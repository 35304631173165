import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@lib/util/logger';
import { BaseComponent } from '@lib/view/base.component';
import { MICaseUsersService } from './mi-case-users.service';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';

@Component({
  selector: 'web-mi-case-users',
  templateUrl: './mi-case-users.component.html',
  styleUrls: ['./mi-case-users.component.scss']
})

export class MiCaseUsersComponent implements OnInit {
  log: Logger = new Logger("MiCaseUsersComponent");

  @Input()
  miCaseId = "-1";
  dataSource = new MatTableDataSource<any>();
  displayColumns = ['name', 'type'];
  userList: any [] = [];

  constructor(
    protected snack: FennecSnackbarService,
    private miCaseUsersService: MICaseUsersService,
    protected route: ActivatedRoute
  ) {
    this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
  }

  ngOnInit() {
    this.getUsersForCase()
  }

  getUsersForCase() {
    if (this.miCaseId == "-1") return;

    this.miCaseUsersService.getCaseUsers(parseInt(this.miCaseId)).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.dataSource.data = response.data
      }
    })
  }

}
