import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { ActivatedRoute} from '@angular/router';
import { DateUtil } from '@lib/util/date-util';
import { MICaseService } from '../mi-case/mi-case.service';
import { DashboardService } from './dashboard.service';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';

import { DASHBOARDS_JSON } from './config/dashboard-config';
import { Dashboards, FilterDefinition } from "@lib/model/interfaces/dashboards";
import { BehaviorSubject, skip, Subject } from "rxjs";
import { DashboardTabulatorComponent } from "@app/dashboard/dashboard-tabulator/dashboard-tabulator.component";
import { MatLegacyTabGroup as MatTabGroup } from "@angular/material/legacy-tabs";
import { BaseComponent } from "@lib/view/base.component";
import { Logger } from "@lib/util/logger";
import {
  DashboardFilterParamDialogComponent
} from "@app/dashboard/dashboard-filter-param-dialog/dashboard-filter-param-dialog.component";
import { SimpleObject } from "@lib/model/simple-object";
import { AdjustmentCodePacket, SavingsByExplanationOrReasonColumn, SavingsByExplAndReasonQueryPacket } from 'xf-common';
import {ColumnDefinition} from "tabulator-tables";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent implements OnInit, AfterViewInit {
  log = new Logger("DashboardComponent");

  dashboardContext: String = "STANDARD";

  readonly SAVINGS_BY_REASON_AND_EXPL_KEY = "savingsByReasonAndExplanation";
  readonly LOCAL_STORAGE_DASHBOARD_FILTERS_KEY = "dashboard-filters";

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  @ViewChild(MatTabGroup)
  tabGroup?: MatTabGroup;

  @ViewChild(DashboardTabulatorComponent)
  activeTabulator?: DashboardTabulatorComponent;

  // These are copies of each other in different format because Angular has troubles
  // iterating through the configs when rendering, unless they're stored in an array.
  dashboardConfigs: Dashboards;
  dashboardConfigArray: any [] = [];

  selectedDashboardKey: string | null = null;

  gridData$ = new Subject<Array<any>>();
  filters$ = new BehaviorSubject<FilterDefinition[]>([]);
  clearSort$ = new Subject<void>();
  exportToXLSX$ = new Subject<void>();
  exportToCSV$ = new Subject<void>();

  // This object stores/holds data from queries performed so we can conjure a good ui experience
  // if the user navigates from dashboard to dashboard. We can refresh the data so the grids do
  // not keep going blank.
  // Key: dashboardName
  // Value: any [] (array of data from server to be visualized in grid)
  gridDataCache$ = new BehaviorSubject<SimpleObject>({});
  get gridDataCache(): SimpleObject {
    return this.gridDataCache$.value;
  }
  set gridDataCache(value: SimpleObject) {
    this.gridDataCache$.next(value);
  }

  // This object stores/holds parameter data from queries performed so we can conjure a good ui experience
  // if the user navigates from dashboard to dashboard. We can refresh the data so the grids do
  // not keep going blank.
  gridParamCache$ = new BehaviorSubject<SimpleObject>({});

  get gridParamCache(): SimpleObject {
    return this.gridParamCache$.value;
  }
  set gridParamCache(value: SimpleObject) {
    this.gridParamCache$.next(value);
  }

  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];

  constructor(
    private caseService: MICaseService,
    private dashboardService: DashboardService,
    protected snack: FennecSnackbarService,
    public matDialog: MatDialog,
    private route: ActivatedRoute
  ) {
    super();

    this.dashboardConfigs = Object.assign({}, DASHBOARDS_JSON);
    this.dashboardConfigArray = [];
    for (const prop in this.dashboardConfigs) {
      this.dashboardConfigArray.push(
        {
          dashboardKey: prop,
          dashboardConfig: this.dashboardConfigs[prop]
        }
      );
    }

    // fetch local storage gridParamCache$
    let userFilters = localStorage.getItem(this.LOCAL_STORAGE_DASHBOARD_FILTERS_KEY);
    if (userFilters != null && userFilters != "") {
      this.gridParamCache$.next(JSON.parse(userFilters));
    }

    // update local storage if gridParamCache$ changes; skip over initial value
    this.gridParamCache$.pipe(skip(1)).subscribe((val) => {
      localStorage.setItem(this.LOCAL_STORAGE_DASHBOARD_FILTERS_KEY, JSON.stringify(val));
    });

    // This code properly sets the dashboardContext from the router url when navigating back and forth
    // from the same component (i.e. this one - dashboardComponent). Setting the value in onInit does
    // not work because onInit does not re-fire on same component navigation (event though the reload
    // option is set in the router config)
    route.params.subscribe(val => {
      // Router path - Set dashboard context so we know what dashboards to expose to the user.
      const dashboardContext = this.route.snapshot.paramMap.get('context');
      if (dashboardContext != null) {
        this.dashboardContext = dashboardContext;
        this.selectFirstDashboard();
      }
    });

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.selectFirstDashboard();
      this.fetchReasonsAndExplanations();
    }, 100);
  }

  selectFirstDashboard() {
    // Select the first dashboard
    this.selectedDashboardKey = null;
    this.dashboardConfigArray.forEach((dc) => {
      if (this.selectedDashboardKey === null && dc.dashboardConfig.dashboardContext.includes(this.dashboardContext)) {
        this.selectDashboard(dc.dashboardKey);
      }
    });
  }

  // We assume everything is already rendered when calling this function.
  selectDashboard(dashboardKey: string) {
    this.selectedDashboardKey = dashboardKey;
    this.activeTabulator?.setDashboardConfig(this.dashboardConfigs[this.selectedDashboardKey]);


    // Refresh grid query params if it's in the cache from a previously executed query.
    const cachedParamData = this.gridParamCache?.[this.selectedDashboardKey];
    if (cachedParamData != null && cachedParamData.length > 0) {
      this.filters$.next(cachedParamData);
    } else {
      this.filters$.next([]);
    }


    // Refresh grid data if it's in the cache from a previously executed query.
    const cachedGridData = this.gridDataCache?.[this.selectedDashboardKey];
    if (cachedGridData != null && cachedGridData.length > 0) {
      this.gridData$.next(cachedGridData);
    } else {
      this.gridData$.next([]);
    }

    if (cachedParamData && !cachedGridData) {
      this.getDashboardData({params: cachedParamData});
    }
  }

  formatDateString(activity: any) {
    return DateUtil.getDisplayDate(activity.createdDateString);
  }

  getDashboardData(paramsPacket: any) {
    if (this.dashboardConfigs == null || this.selectedDashboardKey == null) {
      return;
    }
    const endpoint = this.dashboardConfigs[this.selectedDashboardKey].endpointSuffix;
    if (!endpoint) {
      super.showErrorSnack("This dashboard has not been fully configured. Please notify system support");
      return;
    }

    this.activeTabulator?.setDataLoading(true);

    this.performXFRequest({
      requestDescription: "Get dashboard data",
      requestFn: this.dashboardService.getDashboardData,
      fnParams: [endpoint, paramsPacket],
      onError: errString => {
        this.activeTabulator?.setDataLoading(false);
        super.showErrorSnack(errString);
      },
      onSuccess: data => {
        this.handleNewData(this.selectedDashboardKey, paramsPacket, data);
        this.activeTabulator?.setDataLoading(false);
      }
    });
  }

  handleNewData(dashboardKey: string | null, paramsPacket: any, data: any) {
    // savingsByExplanationAndReason
    // Store data for the purposes of UI experience if the user goes from one dashboard
    // to another we can refresh the previous display for them.
    if (dashboardKey != null) {
      const dataCache = this.gridDataCache;
      dataCache[dashboardKey] = data;
      this.gridDataCache = dataCache;

      const paramCache = this.gridParamCache;
      paramCache[dashboardKey] = paramsPacket["params"];
      this.gridParamCache = paramCache;
    }
    // Alert listeners of what the filter values were that produced the data.
    this.filters$.next(paramsPacket["params"]);

    if (dashboardKey == this.SAVINGS_BY_REASON_AND_EXPL_KEY) {
      const tableData = data as SavingsByExplAndReasonQueryPacket[];
      const outputData = [];
      // reformat incoming data to match grid
      let row, dynamicData: SimpleObject, column;
      let dynamicColumns: SavingsByExplanationOrReasonColumn[];
      for (let i = 0; i < tableData.length; i++) {
        row = tableData[i];
        dynamicData = {};
        dynamicColumns = row['savingsColumns'] ?? [];
        delete data['savingsColumns'];
        for (column of dynamicColumns) {
          if (column.code != null) {
            const userDesc = this.convertAdjustmentTypeToHumanText(column.adjustmentType);
            dynamicData[`${userDesc} ${column.code}`] = column.savingsAmount;
          }
        }
        const finishedRow = Object.assign({}, row, dynamicData);
        outputData.push(finishedRow);
      }
      data = outputData;
    }
    // Alert listeners that we have data.
    this.gridData$.next(data);
  }

  editDashboardFilterParams() {
    if (this.selectedDashboardKey === null || this.selectedDashboardKey === undefined) {
      return;
    }

    // If the user has executed a previous query with parameters, use those values
    // to seed the ui.
    if (this.gridParamCache[this.selectedDashboardKey] != null) {
      this.gridParamCache[this.selectedDashboardKey].forEach((pc: any) => {
        if (this.selectedDashboardKey != null) {
          this.dashboardConfigs[this.selectedDashboardKey].filters.forEach((filter: any) => {
            if (pc.type === filter.dashboardFilterParamType) {
              // On primary_clients we need to convert the value from a string with comma separated values
              // in it to an integer array so the UI can properly use it in a multi-select.
              if (pc.type === "PRIMARY_CLIENTS" || pc.type === "HEALTH_CARE_PLANS") {
                let elements = pc.value.toString().split(",");
                let numArray: any = [];
                elements.forEach((id: any) => {
                  numArray.push(parseInt(id.toString()));
                });
                filter.value = numArray;
              } else {
                // Take the value 'as-is' - string
                filter.value = pc.value;
              }
            }
          });
        }
      });
    }

    //console.log(this.gridParamCache[this.selectedDashboardKey]);
    //console.log(this.dashboardConfigs[this.selectedDashboardKey].filters);

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.data = {
      title: `${this.dashboardConfigs[this.selectedDashboardKey].name} Parameters`,
      paramConfig: this.dashboardConfigs[this.selectedDashboardKey].filters
    };

    const dialogRef = this.matDialog.open(DashboardFilterParamDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result?.confirm) {
        this.filters$.next(result.params);
        // Call EP to get the dashboard data.
        const paramsPacket = {
          params: result.params
        };
        this.getDashboardData(paramsPacket);
      }
    });
  }

  fetchReasonsAndExplanations() {
    this.performXFRequest({
      requestDescription: "Get reasons and explanations",
      requestFn: this.dashboardService.getReasonsAndExplanations,
      onSuccess: (data: AdjustmentCodePacket[]) => {
        const allColumns: ColumnDefinition[] = [];
        const reasons = data
          .filter(v => v.visibleOnDashboard && v.adjustmentType === 'REASON')
          .map(this.convertAdjustmentCodePacketToColumn);
        const explanations = data
          .filter(v => v.visibleOnDashboard && v.adjustmentType === 'EXPLANATION')
          .map(this.convertAdjustmentCodePacketToColumn);
        allColumns.push(...reasons);
        allColumns.push(...explanations);
        this.dashboardConfigs[this.SAVINGS_BY_REASON_AND_EXPL_KEY].columns.push(...allColumns);
      }
    });
  }

  convertAdjustmentCodePacketToColumn = (packet: AdjustmentCodePacket): ColumnDefinition => {
    const userDesc = this.convertAdjustmentTypeToHumanText(packet.adjustmentType);
    return {
      visible: true,
      title: packet.adjustmentType === 'EXPLANATION' ? `${packet.code} ${packet.shortDescription ?? ''}` : `${userDesc} ${packet.shortDescription ?? ''}`,
      field: `${userDesc} ${packet.code ?? ''}` ?? "invalidField", // should never happen, unless there are blank codes in the database.
      bottomCalc: "sum",
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams: {symbol: '$'},
      formatter: "money",
      formatterParams: {symbol: '$'},
      hozAlign: "right"
    };
  }

  convertAdjustmentTypeToHumanText(adjustmentType?: string): string {
    switch (adjustmentType) {
      case "EXPLANATION": return "Explanation";
      case "REASON": return "Reason";
      default: return "";
    }
  }

  getFilterParamValue(dashboardFilterParamType: string): any {
    return this.filters$.value
      .find((val: any) => val.type.toLowerCase() === dashboardFilterParamType.toLowerCase())
      ?.['value']
      ?? null;
  }

  clearSort() {
    this.clearSort$.next();
  }

  clearCache() {
    this.gridParamCache = {};
    this.gridDataCache = {};
    this.filters$.next([]);
    this.gridData$.next([]);
  }

  exportToXLSX() {
    this.exportToXLSX$.next();
  }

  exportToCSV() {
    this.exportToCSV$.next();
  }
}
