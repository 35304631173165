import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseResponse } from "@lib/model/base-response";
import { DataResponse } from "@lib/model/data-response";
import { PagedResponse } from "@lib/model/paged-response";
import { Observable, Subject } from "rxjs";
import { CaseCreate } from "../domain/mi-case-create";
import { CaseEdit } from "@lib/model/net/mi-case-edit";
import { EnvironmentService } from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class MICaseService {

  private miCaseUrl = `${this.environment.baseApiUrl}mi-case/`;

  constructor(
      private httpClient: HttpClient,
      private environment: EnvironmentService
  ) {}

  submitMICase = (miCaseId: number): Observable<any> => {
    return this.httpClient.post<any>(this.environment.baseApiUrl + `mi-case/submit/${miCaseId}`, {});
  }

  dataEntryComplete = (miCaseId: number): Observable<any> => {
      return this.httpClient.post<any>(this.environment.baseApiUrl + `mi-case/de-complete/${miCaseId}`, {});
  }

  openTaskCheck = (miCaseId: number): Observable<any> => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + `mi-case/opentaskcheck/${miCaseId}`);
  }

  getCaseTypes = (): Observable<DataResponse<any[]>> => {
      return this.httpClient.get<DataResponse<any[]>>(this.environment.baseApiUrl + "mi-case/case-types");
  }

  getCases(first?: number, max?: number): Observable<any> {
      return this.httpClient.get<any>(this.environment.baseApiUrl + "mi-case/list?first=" + first + "&max=" + max);
  }

  getAllCases = (queryParams: {}, first?: number, max?: number): Observable<PagedResponse<any>> => {
    return this.httpClient.put<PagedResponse<any>>(this.environment.baseApiUrl + "mi-case/mi-case-list?first=" + first + "&max=" + max, queryParams);
  }

  addCase(payload: CaseCreate): Observable<BaseResponse> {
      return this.httpClient.post<any>(this.environment.baseApiUrl + "mi-case/create", payload);
  }

  updateCase(data: any): Observable<BaseResponse> {
      return this.httpClient.put<any>(this.miCaseUrl, data);
  }

  deleteCase(id: number): Observable<BaseResponse> {
      return this.httpClient.delete<any>(this.miCaseUrl + "destroy/" + id);
  }

  getCaseById(id: number): Observable<DataResponse<any>> {
      return this.httpClient.get<DataResponse<any>>(`${this.miCaseUrl}by-id/${id}`);
  }

  getMICaseMainStatusTrackStatuses = (): Observable<DataResponse<any>> => {
    return this.httpClient.get<any>(`${this.miCaseUrl}status-track-statuses`);
  }

  getMICaseInfo = (id:number): Observable<DataResponse<any>> => {
    return this.httpClient.get<any>(`${this.miCaseUrl}info/${id}`);
  }

  getProviderParValues = (): Observable<any> => {
    return this.httpClient.get<any>(`${this.miCaseUrl}provider-par`);
  }

  getLineOfBusinessValues = (): Observable<any> => {
    return this.httpClient.get<any>(`${this.miCaseUrl}lines-of-business`);
  }

  getCustomerPriorityValues = (): Observable<any> => {
    return this.httpClient.get<any>(`${this.miCaseUrl}customer-priority`);
  }

  updateCaseDialog = (payload: CaseEdit): Observable<DataResponse<any>> => {
    return this.httpClient.put<any>(`${this.environment.baseApiUrl}mi-case/update-dialog`, payload);
  }
}
