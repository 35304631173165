import { Deserializable } from "@lib/model/deserializable";
import { SimpleObject } from "@lib/model/simple-object";
import { DataResponse } from "../data-response";

export class FilePacket {
  fileName?: string;
  fileType?: string;
  mimeType?: string;
  data?: string; // [base-64-encoded(ZLIB-compressed byte-array)]
  config: SimpleObject = {};
}
