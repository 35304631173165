<app-dashboard-filter-param [paramConfig]="this.paramConfig" [title]="title"></app-dashboard-filter-param>

<div matDialogActions class="fennec-dialog-action-button-container">
  <button mat-flat-button color="primary" (click)="cancel()">
    Cancel
  </button>
  <button mat-flat-button color="accent" (click)="submit()">
    Save
  </button>
</div>
