import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse } from '@lib/model/base-response';
import { ClientReportPacket } from '@lib/model/net/itemized-client-report';
import { ItemizedRevisionUpdatePacket } from '@lib/model/net/itemized-revision-update-packet';
import { ProviderReportPacket } from '@lib/model/net/provider-report-packet';
import { Observable } from 'rxjs';
import { EnvironmentService } from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class ItemizedRevisionService {

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  getAllItemizedRevisionsForItemized(id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized-revision/list/itemized/" + id.toString());
  }

  getItemizedRevisionListForItemizedPaginated(id: number, first:number, max:number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized-revision/list/itemized/p/" + id.toString() + "?first=" + first + "&max=" + max);
  }

  getItemizedRevisionInfo(id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized-revision/info/" + id.toString());
  }

  updateDataEntryServiceDates(iRevId: number, serviceDateStartString: string, serviceDateEndString: string): Observable<BaseResponse> {
    let payload = {
      serviceDateStartString: serviceDateStartString,
      serviceDateEndString: serviceDateEndString
    }
    return this.httpClient.put<any>(this.environment.baseApiUrl + "itemized-revision/de-service-dates/" + iRevId.toString()
      , payload);
  }

  sendItemizedRevisionToAPE(id: number): Observable<BaseResponse> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "ape/itemized-revision/claim-analysis/" + id.toString(), {});
  }

  addItemizedRevision(payload: ItemizedRevisionUpdatePacket): Observable<BaseResponse> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "itemized-revision/create", payload);
  }

  generateItemizedProviderReport(payload: ProviderReportPacket): Observable<BaseResponse> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "report/provider-report", payload);
  }

  generateItemizedClientReport(payload: ClientReportPacket): Observable<BaseResponse> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "report/client-report", payload);
  }

}
