import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { MICaseService } from './mi-case.service';
import { BaseComponent } from "@lib/view/base.component";
import { Logger } from '@lib/util/logger';
import { FennecSnackbarService } from "@lib/dialog/fennec-snackbar/fennec-snackbar.service";

@Component({
  selector: 'app-mi-case',
  templateUrl: './mi-case.component.html',
  styleUrls: ['./mi-case.component.scss']
})
export class MiCaseComponent extends BaseComponent implements OnInit {
  log = new Logger("MiCaseComponent");

  miCaseId: string | null = "-1";
  // An object that holds information on the current MI Case that's in play.
  miCaseInfo: any | null = null;
  // Used for programatic repositioning to NEW ub04 when it's created.
  selectedUb04Id: string = "-1";
  selectedItemizedId: string = "-1";
  selectedTabIndex: number = 0;

  // Helper class variables for dynamic routing / tab selection behavior.
  valFragments: any [] = [];
  fromUrl: String | null = null;

  userIsDataEntry: boolean = false;
  userIsAdmin:boolean = false;

  potentialRoutes: any[] = [
    { path: "overview", label: "Overview" },
    { path: "ub04", label: "UB04", requirement: () => this.userIsAdmin || this.userIsDataEntry },
    { path: "itemized", label: "Itemized", requirement: () => this.userIsAdmin || this.userIsDataEntry },
    { path: "attachments", label: "Attachments" },
    { path: "users", label: "Users", requirement: () => this.userIsAdmin },
    { path: "task-list", label: "Tasks", requirement: () => this.userIsAdmin },
    { path: "comments", label: "Comments" }
  ];
  activeRoutes: any[] = [];

  constructor(
    private keycloakService: KeycloakService,
    private route: ActivatedRoute,
    private router: Router,
    private miCaseService: MICaseService,
    protected snack: FennecSnackbarService
  ) {
    super();
    const userRoles = this.keycloakService.getUserRoles();
    this.userIsAdmin = userRoles.includes("ADMIN");
    this.userIsDataEntry = userRoles.includes("DATA_ENTRY");
    this.buildRoutes();
    this.miCaseId = this.route.snapshot.paramMap.get("miCaseId") ?? null;
  }

  ngOnInit(): void {
    this.getInfo();
  }

  buildRoutes(): void {
    this.activeRoutes = this.potentialRoutes.filter(route => route.requirement == null || route.requirement());
  }

  getInfo() {
    if (this.miCaseId === null || this.miCaseId === undefined || this.miCaseId === "-1") {
      return;
    }
    this.performXFRequest({
      requestDescription: "GET Case Info",
      requestFn: this.miCaseService.getMICaseInfo,
      fnParams: [parseInt(this.miCaseId)],
      onResponse: response => {
        this.miCaseInfo = null;
        if (response.data !== null && response.data !== undefined) {
          this.miCaseInfo = response.data;
        }
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

}
