import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BaseResponse } from '@lib/model/base-response';
import { Logger } from '@lib/util/logger';
import { BaseComponent } from '@lib/view/base.component';
import { ItemizedRevisionService } from '../itemized-revision.service';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';

@Component({
  selector: 'app-data-entry-service-date-range-dialog',
  templateUrl: './data-entry-service-date-range-dialog.component.html',
  styleUrls: ['./data-entry-service-date-range-dialog.component.scss']
})
export class DataEntryServiceDateRangeDialogComponent extends BaseComponent implements OnInit {
  protected log: Logger = new Logger("DataEntryServiceDateRangeDialogComponent");

  formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DataEntryServiceDateRangeDialogComponent>,
    private itemizedRevisionService: ItemizedRevisionService,
    protected snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.formGroup = new FormGroup({
      startDate: new FormControl(this.data.startDate, Validators.required),
      endDate: new FormControl(this.data.endDate, Validators.required)
    })
   }

  ngOnInit(): void {
  }

  onYes() {
    let startDateString: string = this.formGroup.controls['startDate'].value;
    let endDateString: string = this.formGroup.controls['endDate'].value;
    if (endDateString < startDateString) {
      this.showErrorSnack("End Date cannot be less than Start Date!");
      return;
    }

    // Update the dates
    this.itemizedRevisionService.updateDataEntryServiceDates(this.data.itemizedRevisionId, 
      startDateString, endDateString).subscribe((response: BaseResponse) => {
      if (response.hasErrors) {
        this.showErrorSnack(response.consolidatedErrorMessage);
        return;
      } else {
        const returnObj = {
          confirm: true
        }
        this.dialogRef.close(returnObj);
      }
    });
  }

  onNo() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

}
