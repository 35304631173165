import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CaseCreate } from '@app/domain/mi-case-create';
import { MICaseService } from '../mi-case.service';
import { CompanyService } from '@app/company/company.service';
import { CompanyQueryPacket001 } from '@lib/model/net/company-query-packet001';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';
import { HealthCarePlanPacket } from '@lib/model/net/health-care-plan.packet';
import { HealthCarePlanService } from '@app/health-care-plan/health-care-plan.service';
import { UserProfileService } from '@app/user-profile/user-profile.service';
import { BaseComponent } from '@lib/view/base.component';
import { Logger } from '@lib/util/logger';
import {DateUtil} from "xf-common";

@Component({
  selector: 'app-create-case',
  templateUrl: './case-create.component.html',
  styleUrls: ['./case-create.component.css']
})
export class CreateCaseComponent extends BaseComponent implements OnInit, AfterViewInit {
  log: Logger = new Logger("CreateCaseComponent");

  formGroup!: FormGroup;
  typeList: String[] = [];
  lineOfBusinessList: any[] = [];
  customerPriorityList: any[] = [];
  providerParOptionsList: String[] = [];
  case: CaseCreate = new CaseCreate();
  userPrimaryClientList: any[] = [];
  // Static list of user health care plans that specifies which health care plans a user has access to based upon the user's MI Case
  // Visibility level. This list should stay static after it's loaded by the server. We build a dynamic health care plan list for the
  // user based upon which primary client they select.
  userHealthCarePlanListStatic: any[] = [];
  // Dynamically built user health care plan list that contains the health care plans associated with the primary client the user has
  // selected on the UI.
  userHealthCarePlanList: any[] = [];
  userProfileId: number = -1;
  userMICaseVisibilityLevel: string = "HEALTH_CARE_PLAN";

  constructor(
    public dialogRef: MatDialogRef<CreateCaseComponent>,
    protected snack: FennecSnackbarService,
    private miCaseService: MICaseService,
    private healthCarePlanService: HealthCarePlanService,
    private userProfileService: UserProfileService
  ) {
    super();
    this.formGroup = new FormGroup({
      type: new FormControl("", Validators.required),
      name: new FormControl("", Validators.required),
      altExternalId: new FormControl(),
      clientCompanyId: new FormControl("", Validators.required),
      healthCarePlanId: new FormControl("", Validators.required),
      lineOfBusiness: new FormControl(""),
      clientClaimId: new FormControl(""),
      providerPar: new FormControl("", Validators.required),
      preReviewPayable: new FormControl(),
      postReviewPayable: new FormControl(),
      drgPaidDate: new FormControl("", DateUtil.dateStringValidator()),
      drgPaymentAmount: new FormControl(),
      networkDiscount: new FormControl(),
      customerPriority: new FormControl(),
      notes: new FormControl("", Validators.maxLength(1000)),
      clientMemberNumber: new FormControl(),
      postPaymentReview: new FormControl(),
      insuredGroupName: new FormControl("", Validators.maxLength(50)),
      insuredGroupNo: new FormControl("", Validators.maxLength(50)),
      clientInternalAdjustments: new FormControl()
    });
    this.getCaseTypes();
    this.getUserProfile();
    this.getUserMICaseVisibility();
    this.getProviderParValues();
    this.getLineOfBusinessValues();
    this.getCustomerPriorityList();
  }
  ngAfterViewInit(): void {
    this.formGroup.controls["clientCompanyId"].valueChanges.subscribe(() => {
      this.getPlansForSelectedClient();
    })
  }

  ngOnInit() {
  }

  getUserMICaseVisibility() {
    this.userProfileService.getUserProfileMICaseVisibilityInfo().subscribe(
      response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage != null ? response.consolidatedErrorMessage : "");
        } else {
          this.userMICaseVisibilityLevel = response.data.visibilityLevel;
          this.userPrimaryClientList = response.data.primaryClients;
          if (this.userPrimaryClientList.length > 0) {
            this.formGroup.controls["clientCompanyId"].setValue(this.userPrimaryClientList[0].companyId);
          }
          if (this.userMICaseVisibilityLevel === "HEALTH_CARE_PLAN") {
            this.userHealthCarePlanListStatic = response.data.userHealthCarePlans;
          } else if (this.userMICaseVisibilityLevel === "PRIMARY_CLIENT") {
            this.userHealthCarePlanListStatic = response.data.allHealthCarePlans;
          }
          this.getPlansForSelectedClient();
        }
      }
    );
  }

  getPlansForSelectedClient = () => {
    const companyId: number = this.formGroup.controls["clientCompanyId"].value;
    this.userHealthCarePlanList = [];
    this.userHealthCarePlanListStatic.forEach((hcp) => {
      if (hcp.companyId === companyId) {
        this.userHealthCarePlanList.push(hcp);
      }
    });
    if (this.userHealthCarePlanList.length > 0) {
      this.formGroup.controls["healthCarePlanId"].setValue(this.userHealthCarePlanList[0].healthCarePlanId);
    }
  }

  getCaseTypes() {
    // Temporarily removing Case Type options for go-live to lock user into selecting "PI_REVIEW"
    // this.miCaseService.getCaseTypes().subscribe(
    //   response => {
    //     if (response.hasErrors) {
    //       this.snack.showErrorSnack(response.consolidatedErrorMessage);
    //     } else {
    //       this.typeList = response.data;
    //       if (this.typeList.length > 0) {
    //         this.formGroup.controls["type"].setValue(this.typeList[0]);
    //       }
    //     }
    //   }
    // );
    this.typeList = ["PI_REVIEW", "APPEAL_REVIEW", "RECONSIDERATION_REVIEW", "RESOLUTION_REVIEW"];
    this.formGroup.controls["type"].setValue(this.typeList[0]);
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    this.case.type = this.formGroup.controls['type'].value;
    this.case.name = this.formGroup.controls['name'].value;
    this.case.altExternalId = this.formGroup.controls['altExternalId'].value;
    this.case.primaryClientCompanyId = this.formGroup.controls['clientCompanyId'].value;
    this.case.lineOfBusiness = this.formGroup.controls['lineOfBusiness'].value;
    this.case.preReviewPayable = this.formGroup.controls['preReviewPayable'].value;
    this.case.postReviewPayable = this.formGroup.controls['postReviewPayable'].value;
    this.case.drgPaidDate = this.formGroup.controls['drgPaidDate'].value;
    this.case.drgPaymentAmount = this.formGroup.controls['drgPaymentAmount'].value;
    this.case.networkDiscount = this.formGroup.controls['networkDiscount'].value;
    this.case.clientClaimId = this.formGroup.controls['clientClaimId'].value;
    this.case.notes = this.formGroup.controls['notes'].value;
    this.case.providerPar = this.formGroup.controls['providerPar'].value;
    this.case.customerPriority = this.formGroup.controls['customerPriority'].value;
    this.case.clientMemberNumber = this.formGroup.controls['clientMemberNumber']?.value;
    this.case.postPaymentReview = this.formGroup.controls['postPaymentReview']?.value;
    this.case.healthCarePlanId = this.formGroup.controls['healthCarePlanId']?.value;
    this.case.insuredGroupName = this.formGroup.controls['insuredGroupName']?.value;
    this.case.insuredGroupNo = this.formGroup.controls['insuredGroupNo']?.value;
    this.case.clientInternalAdjustments = this.formGroup.controls['clientInternalAdjustments']?.value;

    this.miCaseService.addCase(this.case).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true,
          id: response.data.id
        }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack(`Successfully Created!`);
      }
    })
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  getProviderParValues() {
    this.miCaseService.getProviderParValues().subscribe(
      response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.providerParOptionsList = response.data;
          if (this.providerParOptionsList.length > 0) {
            this.formGroup.controls["providerPar"].setValue(this.providerParOptionsList[0]);
          }
        }
      }
    );
  }

  getLineOfBusinessValues() {
    this.miCaseService.getLineOfBusinessValues().subscribe(
      response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.lineOfBusinessList = response.data;
          if (this.lineOfBusinessList.length > 0) {
            this.formGroup.controls["lineOfBusiness"].setValue(this.lineOfBusinessList[0].value);
          }
        }
      }
    );
  }

  getCustomerPriorityList() {
    this.miCaseService.getCustomerPriorityValues().subscribe(
      response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.customerPriorityList = response.data;
          if (this.customerPriorityList.length > 0) {
            this.formGroup.controls["customerPriority"].setValue(this.customerPriorityList[0].value);
          }
        }
      }
    );
  }

  getUserProfile() {
    this.userProfileService.getUserProfile().subscribe((response: any) => {
      if (!response.hasErrors) {
        this.userProfileId = response?.data?.id;
      }
    });
  }
}
