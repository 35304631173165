import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
// @ts-ignore
import {CellComponent, ColumnComponent, ColumnDefinition, RowComponent, TabulatorFull} from 'tabulator-tables';
import { Dashboard} from "@lib/model/interfaces/dashboards";
import {debounceTime, Observable} from "rxjs";

/**
 * dashboard-tabulator component Usage:
 *
 * 1) Set any top level dashboard tabulator component config options that you wish to set to
 * configure the component at the parent level. setDashboardTabulatorConfig(dtcConfig: any)
 * 2) Set column config array using consuming component. This is an array of column configuration
 * objects recognized by Tabulator js. setColumnConfig(columnConfig: any[])
 * 2a) Set date sort column config array using consuming component. This is an array of column configuration
 * objects recognized by Tabulator js that include sortable date format (YYYY-MM-DD - string) until
 * we can get tabulator's date sort to actually work. setColumnConfig(columnConfig: any[])
 * 3) Query for your data using consuming component. Supply the data to this component using the
 * setGridData(...) method. setGridData(gridData: any[])
 * 4) Supply an array of filter parameters and their values. These are custom filter parameters that
 * you designate for the consuming component to use for its query (ex: FROM_DATE, THRU_DATE). Supply
 * this array after you launch and receive data back from a query (i.e. step #2). The component will
 * display the filter types and values to show them the parameters used to get the data that is now
 * displayed in the grid. setFilterParams(filterParams: any[])
 */

@Component({
  selector: 'app-dashboard-tabulator',
  templateUrl: './dashboard-tabulator.component.html',
  styleUrls: ['./dashboard-tabulator.component.scss']
})
export class DashboardTabulatorComponent implements OnInit, AfterViewInit {

  // Specifies whether or not to let the grid automatically create columns. If
  // false, you need to supply a columnConfig in order for the grid to render
  // anything.
  @Input()
  autoColumns = false;

  @Input()
  gridData?: Observable<any[]>;

  @Input()
  clearSort$?: Observable<void>;

  @Input()
  exportToXLSX$?: Observable<void>;

  @Input()
  exportToCSV$?: Observable<void>;

  @Input()
  filters$?: Observable<any[]>;

  private dashboardConfig?: Dashboard;

  // Grid Height - the subtracted amount is a guess of all of the headers above the grid combined.
  componentHeightPX = window.innerHeight - 360;

  // Tabulator fields
  private tableDiv = document.createElement('div'); // this is the div that will contain that tabulator-grid HTML.
  private tableRef: any; // this will become a reference to the tabulator-grid object
  // Grid Data
  private tableData: any[] = [];
  // Tabulator js column config for grid
  private columnConfig: ColumnDefinition [] = [];

  private dataLoading: boolean = false;

  // A complete hack that contains an array of columns that are provided so we can sort
  // the grid by date using YYYY-MM-DD date format (i.e. string sort). This was done because
  // I could not get the tabulator js date sort to work. Spent way too much time on it. If
  // you can figure it out this can probably go away.
  showDateSortColumns: boolean = true;
  private dateSortColumnConfig: any [] = [];

  // Used for testing and grid initialization purposes.
  /*
  private initTableData: any [] = [
    {id:1, name:"Oli Bob", progress:12, gender:"male", rating:1, col:"red", dob:"1984-02-19", car:1},
    {id:2, name:"Mary May", progress:1, gender:"female", rating:2, col:"blue", dob:"1982-04-15", car:true},
    {id:3, name:"Christine Lobowski", progress:42, gender:"female", rating:0, col:"green", dob:"1982-05-22", car:"true"},
    {id:4, name:"Brendon Philips", progress:100, gender:"male", rating:1, col:"orange", dob:"1980-01-08"},
    {id:5, name:"Margret Marmajuke", progress:16, gender:"female", rating:5, col:"yellow", dob:"1999-01-31"},
    {id:6, name:"Frank Harbours", progress:38, gender:"male", rating:4, col:"red", dob:"1966-12-05", car:1},
  ];

  initColumnConfig = [
    {title: "Name", field: "name", sorter: "string", width: 200, editor: false},
    {title: "Progress", field: "progress", sorter: "number", width: 100, editor: false},
    {title: "Gender", field: "gender", sorter: "string", width: 100, editor: false},
    {title: "Rating", field: "rating", sorter: "number", width: 80, editor: false},
    {title: "Color", field: "col", sorter: "string", width: 100, editor: false},
    {title: "DOB", field: "dob", sorter: "string", width: 100, editor: false, sorterParams: { format: "DD/MM/YYYY", alignEmptyValues:"top", }},
  ]; */

  // No Data Initial Grid Presentation
  private initTableData: any [] = [
    {colValue: "No data - Please set filter parameters"}
  ];
  initColumnConfig: ColumnDefinition [] = [
    {title: "", field: "colValue", sorter: "string", width: 400, editable: false, hozAlign: "center", visible: true},
  ];

  // Tabulator Dashboard Component Config
  // A top level configuration file that you can set from your consuming component to customize
  // the things at the component level.
  //
  // Attributes:
  // *) xlsxSheetFileName - the name given to the filename when a xlsx export is done.
  private dashboardTabulatorConfig: Partial<Dashboard> = {
    xlsxSheetFileName : "sheet.xlsx",
    xlsxSheetName : "Dashboard Data",
    csvFileName : "dashboard.csv"
  };

  // Filter params are for display only in this component. This allows the developer to
  // supply an array of filter params used for the query that populates the data in the
  // grid, primarily for user presentation. It will show the filter types and values after the
  // user has submitted the query for the tabulator data.
  //
  // The format of the array - each element is an object:
  // 1) type (string) - type of parameter (dashboardFilterParamType (see server side enum))
  // 2) value (string) - the value of the filter parameter

  showFilterParams: boolean = false;
  filterParams: any [] = [];
  firstTableLoad = true;

  @ViewChild('tabularGridWrapper', { static: true }) wrapperDiv!: ElementRef<HTMLDivElement>;

  setDashboardConfig(dashboardConfig: Dashboard) {
    this.dashboardConfig = dashboardConfig;
  }

  setDataLoading(dataLoading: boolean) {
    this.dataLoading = dataLoading;
  }

  isDataEmpty(): boolean {
    return this.tableData?.length === 0 ?? true;
  }

  isDataLoading(): boolean {
    return this.dataLoading;
  }

  constructor() { }

  ngOnInit(): void {
    this.columnConfig = this.initColumnConfig;
    this.tableData = this.initTableData;
    //this.createTable();
  }

  ngAfterViewInit(): void {
    this.gridData?.pipe(
      debounceTime(300)
    ).subscribe(val => {
      //if (this.firstTableLoad && val == null || val.length == 0) {
      //  this.firstTableLoad = false;
      //  return; // avoid overwriting the "empty table" message
      //}
      //this.firstTableLoad = false;
      this.tableData = val;
      this.columnConfig = this.dashboardConfig?.columns ?? this.initColumnConfig;
      Object.assign(
        this.dashboardTabulatorConfig,
        {
          csvFileName: this.dashboardConfig?.csvFileName,
          sheetName: this.dashboardConfig?.sheetName,
          xlsxSheetFileName: this.dashboardConfig?.xlsxSheetFileName
        }
      );
      this.createTable();
    });

    this.clearSort$?.subscribe(() => this.clearSort());
    this.exportToCSV$?.subscribe(_ => this.exportToCSV());
    this.exportToXLSX$?.subscribe(_ => this.exportToXLSX());
    this.filters$?.subscribe((filterParams) => {
      this.filterParams = filterParams;
      setTimeout(() => {
        if (this.filterParams.length > 0) {
          this.showFilterParams = true;
        } else {
          this.showFilterParams = false;
        }
      })
    });
  }

  // Call this to both create the tabulator js grid and to refresh it.
  private createTable() {
    if (this.isDataEmpty()) {
      return;
    }

    if (!this.tableRef) {

      // create the table with the given Angular parameters
      // for details about the Tabulator parameters - check http://tabulator.info/docs/4.2/options
      this.tableRef = new TabulatorFull(this.tableDiv, {
        data: this.tableData,
        // reactiveData: true, // enable data reactivity - means that array content changes get reflected by the grid (without Angular having to worry)
        columns: this.columnConfig,
        autoColumns: this.autoColumns,
        layout: "fitDataFill",
        selectable: true,
        selectableRangeMode: "click"
      });
      while (this.wrapperDiv.nativeElement.firstChild) {
        this.wrapperDiv.nativeElement.removeChild(this.wrapperDiv.nativeElement.firstChild);
      }
      this.wrapperDiv.nativeElement.appendChild(this.tableDiv);
    } else {
      if (this.columnConfig) {
        this.tableRef?.setColumns(this.columnConfig);
      }
      setTimeout(() => {
        this.tableRef?.replaceData(this.tableData);
      }, 200);
    }

  }

  exportToXLSX() {
    this.tableRef.download("xlsx",
      this.dashboardTabulatorConfig.xlsxSheetFileName,
      {sheetName: this.dashboardTabulatorConfig.xlsxSheetName ?? "Export"});
  }

  exportToCSV() {
    this.tableRef.download("csv", this.dashboardTabulatorConfig.csvFileName);
  }

  clearSort() {
    this.tableRef.clearSort();
  }
}
