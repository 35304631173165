import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataResponse } from '@lib/model/data-response';
import { Observable } from 'rxjs';
import { EnvironmentService } from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class DataEntryService {

  private miCaseUrl = `${this.environment.baseApiUrl}mi-case/`;

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  getCasesForDataEntry(first: number, max: number): Observable<any> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "mi-case/data-entry-list?first=" + first + "&max=" + max);
  }
}
