<mat-dialog-content>
	<h2>{{question}}</h2>
  <div *ngIf="infoLine1 != null">
    {{infoLine1}}
  </div>
</mat-dialog-content>
  
<mat-dialog-actions class="fennec-controls">
	<button mat-flat-button (click)="onYes()" color="accent">
			<mat-icon>check_circle</mat-icon> Yes
	</button>
	<button mat-button mat-stroked-button (click)="onNo()">
			<mat-icon>cancel</mat-icon> No
	</button>
</mat-dialog-actions>
