import { Deserializable } from "@lib/model/deserializable";

export class ItemizedLineItemUpdatePacket {
  id?: number = -1;
  serviceDateString?: string;
  description?: string;
  adjustedAmount?: number;
  billedAmount?: number;
  units?: number;
  revenueCodeId?: number;
  revenueCodeString?: string;
  revenueCodeDescription?: string;
  itemizedRevisionId?: number = -1;
  altId?: string;
  externalAltId?: string;
  adjustmentReasonCode?: string;
  adjustmentReasonCodeDescription?: string;
  adjustmentExplanationCode?: string;
  adjustmentExplanationCodeDescription?: string;
  billPageNo?: number;
  billLineNo?: number;
  preExAdjustedAmount?: number;
  preExExplanation?: string;
  preExAuditComments?: string;
  cptCode?:string;
}

