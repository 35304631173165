import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ItemizedCreateComponent } from '@app/itemized/itemized-create/itemized-create.component';
import { ItemizedLineItemComponent } from '@app/itemized/itemized-line-item/itemized-line-item/itemized-line-item.component';
import { ItemizedRevisionListComponent } from '@app/itemized/itemized-revision/itemized-revision-list/itemized-revision-list.component';
import { ItemizedService } from '@app/itemized/itemized.service';
import { ItemizedUpdatePacket } from '@lib/model/net/itemized-update-packet';
import { Ub04SelectComponent } from './ub04-select/ub04-select.component';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';
import { ActivatedRoute } from "@angular/router";
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'web-mi-case-itemized',
  templateUrl: './mi-case-itemized.component.html',
  styleUrls: ['./mi-case-itemized.component.scss']
})
export class MiCaseItemizedComponent implements OnInit, AfterViewInit {

  @Input()
  miCaseId: string = "-1";

  @Input()
  selectedItemizedId: string = "-1";

  @ViewChild('itemizedRevisionListComponent')
  itemizedRevisionListComponent!: ItemizedRevisionListComponent;

  @ViewChild('itemizedLineItemComponent')
  itemizedLineItemComponent!: ItemizedLineItemComponent;

  @ViewChild('ub04SelectComponent')
  ub04SelectComponent!: Ub04SelectComponent;

  screenMode: string = "OVERVIEW";
  itemizedList: any[] = [];
  itemizedCount: number = 0;
  selectedItemizedRevisionId: string = "-1";
  
  defaultPageSize:number = 3;
  totalRowCount:number = 0;

  @ViewChild('paginator')
  paginator?: MatPaginator;

  constructor(
    private itemizedService: ItemizedService,
    public matDialog: MatDialog,
    private snack: FennecSnackbarService,
    private route: ActivatedRoute
  ) {
    this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
  }

  ngAfterViewInit(): void {
    this.getItemizedList();
    this.itemizedRevisionListComponent?.itemizedLineItemComponentRequested?.subscribe((id) => {
      this.selectedItemizedRevisionId = id;
      this.screenMode = "ITEMIZED-LINE-ITEM"
      setTimeout(() => {
        this.itemizedLineItemComponent?.recalcComponentSize();
        this.itemizedLineItemComponent?.getLineItemQuery();
        this.itemizedLineItemComponent?.getItemizedRevisionInfoQuery();
        // Subscribe to the subject that indicates when a user wants to close the itemized line item editor.
        this.itemizedLineItemComponent?.itemizedLineItemComponentClose?.subscribe((id) => {
          // Refresh the list of itemized revisions. There is info on the card (totals) that may need to be 
          // updated.
          this.itemizedRevisionListComponent?.getItemizedRevisions(parseInt(this.selectedItemizedId));
          this.screenMode = "OVERVIEW";
        });
      }, 100);
    });

    if(this.ub04SelectComponent != null) {
      this.ub04SelectComponent?.saveSuccessful?.subscribe(() => {
        this.getItemizedList();
        this.returnToOverview();
      })
    } 
    
  }

  ngOnInit(): void {
  }

  getItemizedList() {
    
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.itemizedService.getItemizedForMICasePaginated(parseInt(this.miCaseId), first, pageSize).subscribe(
      response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.itemizedList = response.data;
          this.totalRowCount = response['totalRowCount'];
          this.itemizedCount = 0;
          if (this.itemizedList !== null && this.itemizedList !== undefined) {
            this.itemizedCount = this.itemizedList.length;
            if (this.selectedItemizedId === "-1" && this.itemizedList.length > 0) {
              this.selectHighestItemized();
            }
          }
        }
      }
    ) 
  }

  getSelectedStyle(id: number):any {
    if (id.toString() === this.selectedItemizedId) {
      return {
        "width" : "100%",
        "background-color": "powderblue"
      }
    } else {
      return {
        "width" : "100%",
        "background-color": "#E5E6EB"
      }
    }
  }

  selectItemized(id: number) {
    this.selectedItemizedId = id.toString();
    this.itemizedRevisionListComponent?.getItemizedRevisions(id);
  }

  selectHighestItemized() {
    let hId: number = -1;
    this.itemizedList.forEach(itm => {
      if (itm && itm.id && itm.id > hId) {
        hId = itm.id;
      }
    });
    this.selectItemized(hId);
  }

  createItemized() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      miCaseId : this.miCaseId,
      origin: 'MANUAL'
    };
    const dialogRef = this.matDialog.open(ItemizedCreateComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.selectedItemizedId = result.id.toString();
        this.getItemizedList();
      }
    });
  }

  returnToOverview() {
    this.itemizedRevisionListComponent?.getItemizedRevisions(parseInt(this.selectedItemizedId));
    this.screenMode = "OVERVIEW";
  }

 getSelectedItemized() {
    let result = null;
    this.itemizedList.forEach(item => {
      if(item && item.id && item.id === parseInt(this.selectedItemizedId)) {
        result = item;
        return;
      }
    })

    return result;
  }

  getFormattedItemizedName() {
    const itemized = this.getSelectedItemized();

    if(itemized != null && itemized['externalAltId'] != null) {
      return itemized['externalAltId'];
    }
    return `Itemized ID:${this.selectedItemizedId}`
  }

  ub04SelectMode(itemizedId: number) {
    this.ub04SelectComponent.getUb04List(parseInt(this.miCaseId), itemizedId);
    this.screenMode = "UB04-SELECT";
  }

}
