import {BehaviorSubject, Observable, ReplaySubject, takeUntil} from "rxjs";
import {Component, OnDestroy} from "@angular/core";
import {
  CallbackType,
  ExtractCallback,
  ExtractData,
  ExtractParams,
  Fn,
  XFRequestDefinition,
  XFRequestHandlers,
  VarLengthTuple
} from "../model/safe-request";
import {emissionWrapper} from "../util/emission-wrapper";

import {HttpErrorResponse} from "@angular/common/http";
import { Logger } from "../util/logger";
import { FennecSnackbarService } from "../dialog/fennec-snackbar/fennec-snackbar.service";


const superLog = new Logger("BaseComponent (superclass)");

@Component({
  template: ''
})
export abstract class BaseComponent implements OnDestroy {
  protected abstract snack: FennecSnackbarService;
  protected abstract log: Logger;

  public destroyed$ = new ReplaySubject<void>(1);
  public loading$ = new BehaviorSubject<boolean>(false);

  get isLoading(): boolean {
    return this.loading$.value;
  }

  set isLoading(value: boolean) {
    this.loading$.next(value);
  }

  private readonly baseXFRequestErrorMessage = "performXFRequest called with invalid parameters";

  // @formatter:off
  performXFRequest<
    R extends Fn<P, C, D>,
    C extends CallbackType<D> = ExtractCallback<R>,
    D = ExtractData<C>,
    // @ts-ignore
    P extends VarLengthTuple<any> = ExtractParams<R>
  >(params: XFRequestDefinition<R, C, D, P> & XFRequestHandlers<D, C>) {

    const {
      requestDescription,
      requestFn,
      onSuccess,
      onResponse,
      onComplete,
      onError,
      noErrorPrefix,
      noErrorFormatting
    } = params;

    const fnParams: P = params.fnParams as P || [] as unknown as P;
    let {cancelRequest$} = params;
    if (!cancelRequest$) {
      cancelRequest$ = new Observable();
    }

    const emitOnComplete = emissionWrapper(onComplete);
    const emitOnSuccess = emissionWrapper(onSuccess);
    const emitOnError = emissionWrapper(onError);
    const emitOnResponse = emissionWrapper(onResponse);

    if (requestDescription == null || requestFn == null) {
      this.log.warn(this.baseXFRequestErrorMessage);
    }

    if (typeof requestFn !== "function") {
      // superLog.group(this.baseXFRequestErrorMessage);
      this.log.error(this.baseXFRequestErrorMessage);
      this.log.error("requestFn must be a function.");
      this.log.debug(`description: ${requestDescription}`);
      this.log.debug(`requestFn: ${JSON.stringify(requestFn)}`);
      this.log.debug(`fnParams: ${JSON.stringify(fnParams)}`);
      // superLog.groupEnd();
      return;
    }

    requestFn(...fnParams)
      .pipe(takeUntil(this.destroyed$), takeUntil(cancelRequest$))
      .subscribe(
        (response: C) => {
          if (response.hasErrors) {
            emitOnError(
              noErrorFormatting
                ? response.errors?.join("\n") ?? ""
                : this.processError(noErrorPrefix ? "" : `Could not perform query [${requestDescription}]`, response.errors)
            );
            emitOnComplete();
          } else {
            setTimeout(() => {
              (this.log || superLog).info(`Successfully performed query ${requestDescription}`);
              emitOnResponse(response);
              // @ts-ignore
              emitOnSuccess(response?.data ?? "Success");
              emitOnComplete();
            });
          }
        },
        (error: HttpErrorResponse) => {
          emitOnError(
            this.processError(noErrorPrefix ? "" : `[${requestDescription}]: HTTP error encountered.`, error)
          );
          emitOnComplete();
        }
      );
  }

  promiseXFRequest<
    R extends Fn<P, C, D>,
    C extends CallbackType<D> = ExtractCallback<R>,
    D = ExtractData<C>,
    // @ts-ignore
    P extends VarLengthTuple<any> = ExtractParams<R>
  >(params: XFRequestDefinition<R, C, D, P>): Promise<D> {
    const self = this;
    let newParams: XFRequestDefinition<R, C, D, P> & XFRequestHandlers<D, C> = params;
    return new Promise((resolve, reject) => {
      newParams.onSuccess = resolve;
      newParams.onError = reject;
      // @ts-ignore
      self.performXFRequest(newParams);
    });
  }
  // @formatter:on

  showSuccessSnack(message: string, duration = 5000) {
    this.snack?.showSuccessSnack(message);
  }

  showInfoSnack(message: string, duration = 5000) {
    this.snack?.showInfoSnack(message);
  }

  showWarningSnack(message: string, duration = 5000) {
    this.snack?.showWarningSnack(message);
  }

  showErrorSnack(message: string, duration = undefined) {
    this.snack?.showErrorSnack(message);
  }

  showWorkerSubmitSnack(message: string, duration = 5000) {
    this.snack?.showWorkerSubmitSnack(message);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public processError(message: string, error?: any): string {
    let errorString = message;
    if (!error) {
      return errorString;
    }
    if (Array.isArray(error) && error.length > 0) {
      errorString = error.reduce((errs: string, err: any) => {
        return `${errs} \n ${err.message ?? err}`;
      }, errorString);
    } else if (error instanceof ErrorEvent) {
      const errMsg = error?.error?.message ?? error?.message ?? error;
      errorString += `\n ${errMsg}`;
    } else if (error instanceof HttpErrorResponse) {
      const errMsg = error?.error?.message ?? error?.message ?? error;
      errorString += `\n HTTP Status: ${error.status}. Error: ${errMsg}`;
    }
    this.isLoading = false;
    return errorString;
  }
}
