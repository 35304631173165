import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '@lib/util/logger';
import { ActivityDiaryService } from './activity-diary.service';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';

@Component({
  selector: 'app-activity-diary',
  templateUrl: './activity-diary.component.html',
  styleUrls: ['./activity-diary.component.scss']
})
export class ActivityDiaryComponent implements OnInit {

  log: Logger = new Logger("ActivityDiaryComponent");

  @Input()
  userId = -1;
  dataSource = new MatTableDataSource<any>()
  displayColumns = ['createdDateString', 'activityDiaryDomain', 'miCaseId', 'activityDiaryMessageType', 'messageText', 'errorFlag'];

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];

  constructor(
    protected snack: FennecSnackbarService,
    private activityDiaryService: ActivityDiaryService,
    protected activeRoute: ActivatedRoute,
    private router: Router

  ) {
    this.userId = this.activeRoute.snapshot.params["user_profile_id"];
   }

  ngOnInit() {
    this.getRecentAcitivtyList();
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getRecentAcitivtyList();
      })
    }
  }

  getRecentAcitivtyList() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.activityDiaryService.getRecentActivityList(first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.dataSource.data = response.data;
        this.totalRowCount = response.totalRowCount;
      }
    })
  }

  getRowStyle(row: any) {
    // use toString because of boolean value returned
    if (row.errorFlag.toString() === 'false') {
      return {
        "background-color": "#ccffcc"
      }
    } if (row.errorFlag.toString() === 'true') {
      return {
        "background-color": "#ffb3b3"
      }
    } else {
      return { "background-color": "beige" }
    }
  }

}
