import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CommentListDialogComponent } from '@app/comments/comment-list-dialog/comment-list-dialog.component';
import { ItemizedService } from '@app/itemized/itemized.service';
import { ClientReportPacket } from '@lib/model/net/itemized-client-report';
import { ItemizedUpdatePacket } from '@lib/model/net/itemized-update-packet';
import { ProviderReportPacket } from '@lib/model/net/provider-report-packet';
import { Subject } from 'rxjs';
import { DataEntryServiceDateRangeDialogComponent } from '../data-entry-service-date-range-dialog/data-entry-service-date-range-dialog.component';
import { ItemizedRevisionCreateComponent } from '../itemized-revision-create/itemized-revision-create.component';
import { ItemizedRevisionService } from '../itemized-revision.service';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-itemized-revision-list',
  templateUrl: './itemized-revision-list.component.html',
  styleUrls: ['./itemized-revision-list.component.scss']
})
export class ItemizedRevisionListComponent implements OnInit, AfterViewInit {

  @Input()
  itemizedId: string = "-1";
  miCaseId?;

  // Fires when the user requests to go to the itemized line item component.
  itemizedLineItemComponentRequested: Subject<any> = new Subject<any>();

  itemizedRevisionList: any [] = [];
  selectedItemizedRevisionId: string = "-1";
  selectedItemizedRevision: any = null;

  @Input()
  itemized?: ItemizedUpdatePacket;

  defaultPageSize:number = 4;
  totalRowCount:number = 0;

  @ViewChild('paginator')
  paginator?: MatPaginator;

  constructor(
    private itemizedRevisionService: ItemizedRevisionService,
    private snack: FennecSnackbarService,
    public matDialog: MatDialog,
    private router: ActivatedRoute
  ) {
    this.miCaseId = this.router?.snapshot?.paramMap?.get('id');
   }

  ngAfterViewInit(): void {
    this.getItemizedRevisions(parseInt(this.itemizedId, 10));
  }

  ngOnInit(): void {
  }

  getSelectedStyle(id: number) {
    if (id.toString() === this.selectedItemizedRevisionId) {
      return {
        "background-color": "powderblue"
      }
    } else {
      return {
        "background-color": "#E5E6EB"
      }
    }
  }

  getItemizedRevisions(itemizedId: number) {
    if (itemizedId !== parseInt(this.itemizedId, 10)) {
      this.selectedItemizedRevisionId = "-1";
    }
    if (itemizedId < 0) {
      return;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.itemizedRevisionService.getItemizedRevisionListForItemizedPaginated(itemizedId, first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.itemizedRevisionList = response.data;
        this.totalRowCount = response['totalRowCount'];
        if (this.selectedItemizedRevisionId === "-1") {
          if (this.itemizedRevisionList.length > 0) {
            this.selectItemizedRevision(this.itemizedRevisionList[0].id);
          } 
        } else {
          this.selectItemizedRevision(parseInt(this.selectedItemizedRevisionId, 10));
        }      
      }
    });
  }

  createItemizedRevision() {

  }

  selectItemizedRevision(id: number) {
    this.selectedItemizedRevisionId = id.toString();
    this.selectedItemizedRevision = this.getSelectedItemizedRevision();
  }

  getSelectedItemizedRevision(): any {
    if (parseInt(this.selectedItemizedRevisionId) < 0) {
      return null;
    } else {
      let ir = null;
      this.itemizedRevisionList.forEach((el) => {
        if (el.id === parseInt(this.selectedItemizedRevisionId)) {
          ir = el;
        }
      });
      return ir;
    }
  }

  cloneItemizedRevision() {
    alert("Coming Soon!");
  }

  sendToAPE() {
    alert("TODO: Prompt for Approval!");
    this.itemizedRevisionService.sendItemizedRevisionToAPE(parseInt(this.selectedItemizedRevisionId))
      .subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.snack.showWorkerSubmitSnack("Sent to APE!");       
      }
    });
  }

  goToItemizedLineItems() {
    this.itemizedLineItemComponentRequested.next(this.selectedItemizedRevisionId);
  }

  createManualItemizedRevision() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      itemizedId : this.itemizedId,
    };
    const dialogRef = this.matDialog.open(ItemizedRevisionCreateComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.selectedItemizedRevisionId = result.id.toString();
        this.getItemizedRevisions(parseInt(this.itemizedId, 10));
      }
    });
  }

  setDataEntryServiceDateRange() {
    let iRev = this.getSelectedItemizedRevision();
    if (iRev === null || iRev === undefined) {
      return;
    }
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      itemizedRevisionId: parseInt(this.selectedItemizedRevisionId),
      startDate: iRev.info?.deServiceStartDateString,
      endDate: iRev.info?.deServiceEndDateString
    };
    const dialogRef = this.matDialog.open(DataEntryServiceDateRangeDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.getItemizedRevisions(parseInt(this.itemizedId, 10));
      }
    });
  }

  /* Removed from Web for now - may bring this back in the future
  createProviderReportPDF() {
    const packet = new ProviderReportPacket();
    packet.itemizedRevisionId = parseInt(this.selectedItemizedRevisionId);
    if(this.miCaseId){
      packet.miCaseId = parseInt(this.miCaseId);
    }

    this.itemizedRevisionService.generateItemizedProviderReport(packet).subscribe(response => {
      if (response.hasErrors) {
        this._snackBar.open(response.consolidatedErrorMessage, "OK");
      } else {
        this._snackBar.open("Generating Itemized Provider Report", "OK");      
      }
    });
  }

  createItemizedClientReportPDF() {
    const packet = new ClientReportPacket();
    packet.itemizedRevisionId = parseInt(this.selectedItemizedRevisionId);
    if(this.miCaseId){
      packet.miCaseId = parseInt(this.miCaseId);
    }

    this.itemizedRevisionService.generateItemizedClientReport(packet).subscribe(response => {
      if (response.hasErrors) {
        this._snackBar.open(response.consolidatedErrorMessage, "OK");
      } else {
        this._snackBar.open("Generating Itemized Client Report", "OK");      
      }
    });
  } */

  openCommentsDialog = () => {
    const itemizedRevisionId = parseInt(this.selectedItemizedRevisionId);
    
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "75vw";
    matDialogConfig.data = {
      mode: "ITEMIZED_REVISION",
      relatedId: itemizedRevisionId
    };

    const dialogRef = this.matDialog.open(CommentListDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getItemizedRevisions(parseInt(this.itemizedId, 10));
    });
  } 

  pageHandler() {
    this.getItemizedRevisions(parseInt(this.itemizedId, 10));
  }
}
