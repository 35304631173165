import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse } from '@lib/model/base-response';
import { Observable } from 'rxjs';
import { UserProfile } from '../domain/user-profile';
import { EnvironmentService } from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  getUserProfile = (): Observable<BaseResponse> => {
    return this.httpClient.get<BaseResponse>(this.environment.baseApiUrl + "userprofile");
  }

  getUserProfileMICaseVisibilityInfo = (): Observable<BaseResponse> => {
    return this.httpClient.get<BaseResponse>(this.environment.baseApiUrl + "userprofile/usermicasevisibilityinfo");
  }

  getDistinctUserProfileForMICaseUserType = (miCaseUserType: string): Observable<BaseResponse> => {
    return this.httpClient.get<BaseResponse>(this.environment.baseApiUrl + "userprofile/distinct-mi-case-users/" + miCaseUserType);
  }

  updateUserProfile = (data: UserProfile): Observable<BaseResponse> => {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "userprofile/update", data);
  }

}
