import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommentPacket } from '@lib/model/net/CommentPacket';
import { CommentRelationalMapPacket } from '@lib/model/net/CommentRelationalMapPacket';
import { PagedResponse } from '@lib/model/paged-response';
import { Logger } from '@lib/util/logger';
import { BaseService } from '@lib/view/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { EnvironmentService } from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class CommentService extends BaseService {
  baseEndpoint = this.environment.baseApiUrl + "comment/";

  constructor(
    public override httpClient: HttpClient,
    override environment: EnvironmentService
  ) {
    super(new Logger("CommentService"), environment);
  }

  getComments = (packet:{}, first:number, max:number): Observable<PagedResponse<CommentPacket[]>> => {
    return this.httpClient.post<PagedResponse<CommentPacket[]>>(`${this.baseEndpoint}list?first=${first}&max=${max}`, packet);
  }

  createComment = (packet:CommentRelationalMapPacket): Observable<any> => {
    return this.httpClient.post(`${this.baseEndpoint}create`, packet);
  }

  editComment = (packet: CommentPacket): Observable<any> => {
    return this.httpClient.post(`${this.baseEndpoint}update`, packet)
  }

  getCommentTypes = (): Observable<any> => {
    return this.httpClient.get(`${this.baseEndpoint}types`);
  }

  deleteComment = (id:number): Observable<any> => {
    return this.httpClient.delete(`${this.baseEndpoint}delete/${id}`);
  }
}
