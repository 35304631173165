import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PagedResponse } from "@lib/model/paged-response";
import { Observable } from "rxjs";
import { EnvironmentService } from "xf-common";

@Injectable({
    providedIn: 'root'
  })
export class MICaseXfwfService {


    constructor(
        private httpClient: HttpClient,
        private environment: EnvironmentService
    ) {}

    getCaseTasks(miCaseId: number, first?: number, max?: number): Observable<PagedResponse<any>> {
        return this.httpClient.get<any>(this.environment.baseApiUrl + `mi-case/task-list/` + miCaseId + "?first=" + first + "&max=" + max);
    }

}
