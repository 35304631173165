<lib-page>
  <ng-container pageTitle>
    Dashboards [{{dashboardContext}}]
  </ng-container>
  <ng-container pageToolbar>
    <div class="dashboard-actions-div-wrapper">
      <button class="dashboard-tabulator-action-button" mat-button mat-raised-button color="accent" (click)="editDashboardFilterParams()">
        Filter Parameters
      </button>
      <button class="dashboard-tabulator-action-button" mat-button mat-raised-button (click)="clearSort()">
        Clear Sort
      </button>
      <button class="dashboard-tabulator-action-button" mat-button mat-raised-button (click)="clearCache()">
        Clear Filter Cache
      </button>
      <button class="dashboard-tabulator-action-button" mat-button mat-raised-button (click)="exportToXLSX()">
        Export to XLSX
      </button>
      <button class="dashboard-tabulator-action-button" mat-button mat-raised-button (click)="exportToCSV()">
        Export to CSV
      </button>
    </div>
  </ng-container>
  <ng-container pageContent>
    <table class="dashboard-navigation-table" style="width: 100%">
      <tr>
        <ng-container *ngFor="let dc of dashboardConfigArray">
          <td *ngIf="dc.dashboardConfig.dashboardContext.includes(dashboardContext)">
            <div *ngIf="selectedDashboardKey !== dc.dashboardKey" class="dashboard-navigation-button"
              (click)="selectDashboard(dc.dashboardKey)">
              {{dc.dashboardConfig.name}}
            </div>
            <div *ngIf="selectedDashboardKey === dc.dashboardKey" class="dashboard-navigation-button-selected">
              {{dc.dashboardConfig.name}}
            </div>
          </td>
        </ng-container>
      </tr>
    </table>
    <div class="dashboard-div-wrapper">
      <app-dashboard-tabulator
        [gridData]="gridData$.asObservable()"
        [clearSort$]="clearSort$.asObservable()"
        [exportToCSV$]="exportToCSV$.asObservable()"
        [exportToXLSX$]="exportToXLSX$.asObservable()"
        [filters$]="filters$.asObservable()"
      ></app-dashboard-tabulator>
    </div>
  </ng-container>
</lib-page>
