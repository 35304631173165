import { Deserializable } from "../deserializable";
import { FilePacket } from "@lib/model/net/filePacket";

export class AttachmentPacket {
  id?: number;
  name?: string;
  miCaseId?: number;
  externalObjectId?: string;
  attachmentType?: string;
  filePacket?: FilePacket;
  publishToWeb?: boolean;
}
