import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {style, state, animate, transition, trigger} from '@angular/animations';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemizedCreateComponent } from '@app/itemized/itemized-create/itemized-create.component';
import { UB04CreateComponent } from '@app/ub04/ub04-create/ub04-create.component';
import { BaseComponent } from '@lib/view/base.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Logger } from '@lib/util/logger';
import { MICaseService } from '../mi-case.service';
import { KeycloakService } from 'keycloak-angular';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';
import { CaseEdit } from '@lib/model/net/mi-case-edit';
import { CaseEditComponent } from '../case-edit/case-edit.component';

@Component({
  selector: 'web-mi-case-overview',
  templateUrl: './mi-case-overview.component.html',
  styleUrls: ['./mi-case-overview.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(500, style({opacity:1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity:0}))
      ])
    ])
  ]
})
export class MiCaseOverviewComponent extends BaseComponent implements OnInit {

  log: Logger = new Logger("CompanyAddComponent");

  @Input()
  miCaseId?: string | null;

  formGroup: FormGroup;
  changesMade: boolean = false;
  miCase: any;

  userIsDataEntry: boolean = false;
  userIsAdmin:boolean = false;

  typeDisplay: string = "";
  lobDisplay: string = "";
  priorityDisplay: string = "";

  constructor(
    private keycloakService: KeycloakService,
    protected override snack: FennecSnackbarService,
    public matDialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    public miCaseService: MICaseService,
  ) {
    super();
    this.formGroup = this.createFormGroup();
    const userRoles = this.keycloakService.getUserRoles();
    this.userIsAdmin = userRoles.includes("ADMIN");
    this.userIsDataEntry = userRoles.includes("DATA_ENTRY");
    this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId");
  }

  createFormGroup(): FormGroup {
    const newFormGroup = new FormGroup({
      name: new FormControl(),
      altExternalId: new FormControl(),
    });

    if(this.userIsDataEntry) {
      newFormGroup.disable();
    }

    newFormGroup.valueChanges.subscribe(() => {
      this.changesMade = true;
    })

    return newFormGroup;
  }

  ngOnInit(): void {
    this.getMICase();
  }

  getMICase() {
    if (this.miCaseId === null || this.miCaseId === undefined || this.miCaseId === "-1") {
      return;
    }
    this.performXFRequest({
      requestDescription: "GET Case Info",
      requestFn: this.miCaseService.getMICaseInfo,
      fnParams: [parseInt(this.miCaseId)],
      onResponse: response => {
        this.miCase = null;
        if (response.data !== null && response.data !== undefined) {
          this.miCase = response.data;
          this.formGroup.controls['name'].setValue(this.miCase.name);
          this.formGroup.controls['altExternalId'].setValue(this.miCase.altExternalId);
        }
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  populateFormGroup() {
    const newFormGroup = new FormGroup({
      name: new FormControl(this.miCase.name),
      altExternalId: new FormControl(this.miCase.altExternalId),
    });

    newFormGroup.valueChanges.subscribe(() => {
      this.changesMade = true;
    })

    this.formGroup = newFormGroup;

  }

  createUB04() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      miCaseId : this.miCaseId,
    };
    const dialogRef = this.matDialog.open(UB04CreateComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.router.navigateByUrl(`/mi-case/${this.miCaseId}/ub04/${result.id}`);
      }
    });
  }

  createItemized() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      miCaseId : this.miCaseId,
      origin: 'MANUAL'
    };
    const dialogRef = this.matDialog.open(ItemizedCreateComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.router.navigateByUrl(`/mi-case/${this.miCaseId}/itemized/${result.id}`);
      }
    });
  }

  accessAttachments() {
    this.router.navigateByUrl(`/mi-case/${this.miCaseId}/attachments`);
  }

  // updateMICase() {
  //   this.miCase.name = this.formGroup.controls['name'].value;
  //   this.miCase.altExternalId = this.formGroup.controls['altExternalId'].value;

  //   this.miCaseService.updateCase(this.miCase).subscribe(response => {
  //     if(response.hasErrors) {
  //       this.showErrorSnack("Error updating MICase");
  //     }else {
  //       this.showSuccessSnack("MICase Updated Successfully");
  //     }
  //   });
  // }

  // calculateDisplayValues() {
  //   if(!this.miCase) {
  //     return;
  //   }

  //   const priority = this.miCase.customerPriority;
  //   if (this.customerPriorityList && priority != null && priority != "") {
  //     const enumValue = this.customerPriorityList.find(prio => priority.toLowerCase() === prio.value.toLowerCase());
  //     if (enumValue != null) {
  //       this.priorityDisplay = enumValue.label;
  //     }
  //   }
  //   const lob = this.miCase.lineOfBusiness;
  //   if (this.linesOfBusinessList && lob != null && lob != "") {
  //     const enumValue = this.linesOfBusinessList.find(enumVal => lob.toLowerCase() === enumVal.value.toLowerCase());
  //     if (enumValue != null) {
  //       this.lobDisplay = enumValue.label;
  //     }
  //   }
  //   const type = this.miCase.type;
  //   if (this.typeList && type != null && type != "") {
  //     const enumValue = this.typeList.find(enumVal => type.toLowerCase() === enumVal.value.toLowerCase());
  //     if (enumValue != null) {
  //       this.typeDisplay = enumValue.label;
  //     }
  //   }
  // }

  handleOpenEditDialog(miCase: any) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      miCase: miCase
    }
    const dialogRef = this.matDialog.open(CaseEditComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.updateCase(result.case);
      }
    });
  }

  updateCase(miCase: any) {
    const updatedCasePacket = Object.assign(new CaseEdit(), miCase);

    this.performXFRequest({
      requestDescription: "Update Case Info",
      requestFn: this.miCaseService.updateCaseDialog,
      fnParams: [updatedCasePacket],
      onSuccess: (_data) => {
        this.snack.showSuccessSnack("Case updated successfully");
        this.getMICase();
        // this.caseUpdated.emit();
      },
      onError: (err) => {
        this.snack.showErrorSnack(err);
      }
    });
  }
}
