import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';
import { Logger } from '@lib/util/logger';
import { BaseComponent } from '@lib/view/base.component';

@Component({
  selector: 'app-data-entry-attachment-dialog',
  templateUrl: './data-entry-attachment-dialog.component.html',
  styleUrls: ['./data-entry-attachment-dialog.component.scss']
})
export class DataEntryAttachmentDialogComponent extends BaseComponent implements OnInit {

  protected log = new Logger("DataEntryAttachmentDialogComponent");
  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<DataEntryAttachmentDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) protected data: any
  ) {
    super();
  }

  ngOnInit(): void {
  }

}
