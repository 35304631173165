import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UB04Create } from '@app/domain/ub04-create';
import { Ub04Service } from '../ub04.service';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';

@Component({
  selector: 'app-ub04-create',
  templateUrl: './ub04-create.component.html',
  styleUrls: ['./ub04-create.component.css']
})
export class UB04CreateComponent implements OnInit {

  formGroup!: FormGroup;
  typeList: String[] = [];
  ub04ReviewTypes: any = [];
  ub04Dto: UB04Create = new UB04Create();

  constructor(
    public dialogRef: MatDialogRef<UB04CreateComponent>,
    private snack: FennecSnackbarService,
    private ub04Service: Ub04Service,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.formGroup = new FormGroup({
      npiNumber: new FormControl(""),
      ub04VisitType: new FormControl('INPATIENT'), 
      ub04ReviewType: new FormControl('CLAIM_REVIEW')
    });
  }

  ngOnInit() {
    this.getUB04ReviewTypes();
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    this.ub04Dto.miCaseId = this.data.miCaseId;
    this.ub04Dto.npiNumber = this.formGroup.controls['npiNumber'].value;
    this.ub04Dto.ub04VisitType = this.formGroup.controls['ub04VisitType'].value;
    this.ub04Dto.ub04ReviewType = this.formGroup.controls['ub04ReviewType'].value;
    this.ub04Service.addUB04(this.ub04Dto).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true,
          id: response.data.id
        }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack(`Successfully Created!`);
      }
    })
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  getUB04ReviewTypes() {
    this.ub04Service.getAllUB04ReviewTypes().subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.ub04ReviewTypes = response.data;
        if (this.ub04ReviewTypes.length > 0) {
          this.formGroup.controls['ub04ReviewType'].setValue(this.ub04ReviewTypes[0].ub04ReviewType);
        }
      }
    });
  }

}
