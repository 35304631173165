import { Dialog } from '@angular/cdk/dialog';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserProfileService } from './user-profile.service';
import { UserProfile } from '../domain/user-profile';
import { AddressComponent } from '../address/address.component';
import { BaseResponse } from '@lib/model/base-response';
import { BaseComponent } from '@lib/view/base.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Logger } from '@lib/util/logger';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent extends BaseComponent implements OnInit, AfterViewInit {

  log: Logger = new Logger("UserProfileComponent");

  firstName: string = "";
  lastName: string = "";
  id?: number;
  userId: string = "";
  addressId?: number;
  userMICaseVisibilityLevel?: string;
  
  @ViewChild('addressComponent')
  addressComponent!: AddressComponent;

  constructor(
    private userProfileService: UserProfileService,
    protected matDialog: MatDialog,
    protected override snack: FennecSnackbarService,
  ) {
    super();
  }

  ngAfterViewInit(): void {  
    if (this.addressComponent !== null && this.addressComponent !== undefined) {
      this.addressComponent.addressSaveComplete.subscribe((addressId) => {
        this.addressId = addressId;
        this.addressComponent.addressId = addressId;
        this.saveUserProfile();
      });
    }
  }

ngOnInit(): void {
  this.userProfileService.getUserProfile().subscribe((response: BaseResponse) => {
    if (response.hasErrors) {
      this.snack.showErrorSnack(response.consolidatedErrorMessage);
    } else {
      this.id = response.data.id;
      this.userId = response.data.userId;
      this.firstName = response.data.firstName;
      this.lastName = response.data.lastName;
      this.userMICaseVisibilityLevel = response.data.userMICaseVisibilityLevel;
      this.addressId = response.data.addressId;
      this.addressComponent?.getAddressById(this.addressId);
      }
    });
  }

  onSave() {
    this.addressComponent.saveAddress.next(null);
  }

  addressComponentFormValid(): boolean {
    return this.addressComponent !== null && this.addressComponent !== undefined ? 
      this.addressComponent.formGroup.valid : false;
  }
  
  saveUserProfile(){
    const userProfile = {
      id: this.id,
      userId: this.userId,
      firstName: this.firstName,
      lastName: this.lastName,
      userMICaseVisibilityLevel: this.userMICaseVisibilityLevel,
      addressId: this.addressId
    }

    this.userProfileService.updateUserProfile(userProfile).subscribe((response:BaseResponse) => {
      if (response.hasErrors) {
        this.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.showSuccessSnack("Update Complete!");
      }
    });
  }
}
