import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataResponse } from '@lib/model/data-response';
import { PagedResponse } from '@lib/model/paged-response';
import { Observable } from 'rxjs';
import { BaseService } from "@lib/view/base.service";
import { Logger } from "@lib/util/logger";
import { EnvironmentService, SavingsByExplAndReasonQueryPacket } from "xf-common";

const log = new Logger("DashboardService");

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService {
  baseEndpoint = super.apiEndpoint("dashboard");

  constructor(
    override httpClient: HttpClient,
    override environment: EnvironmentService
  ) {
    super(log, environment);
  }

  getDashboardData = (endpointSuffix: string, paramsPacket: any): Observable<DataResponse<any[]>> => {
    return this.httpClient.put<any>(`${this.environment.baseApiUrl}dashboard/${endpointSuffix}`, paramsPacket);
  }

  getRecentActivityList(first?: number, max?: number): Observable<PagedResponse<any>> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}activity-diary/list/?first=${first}&max=${max}`);
  }

  getMICaseMonthlyCountInfo(startYearMonth:string, endYearMonth:string): Observable<DataResponse<any>> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}mi-case/monthly-count?startYearMonth=${startYearMonth}&endYearMonth=${endYearMonth}`);
  }

  getReasonsAndExplanations = (): Observable<DataResponse<SavingsByExplAndReasonQueryPacket[]>> => {
    const ep = `${this.baseEndpoint}/all-reasons-and-explanations`;
    return this.httpClient.get<DataResponse<SavingsByExplAndReasonQueryPacket[]>>(ep);
  }
}
