import {Injectable, OnDestroy} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {Logger} from "../util/logger";
import {HttpClient} from "@angular/common/http";
import { EnvironmentService } from "xf-common";

@Injectable()
export abstract class BaseService implements OnDestroy {
  public abstract baseEndpoint?: string;
  public abstract httpClient: HttpClient;

  protected constructor(
    public log: Logger,
    public environment: EnvironmentService
  ) {}

  public destroyed$ = new ReplaySubject<void>(1);
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  apiEndpoint(url: string): string {
    return `${this.environment.baseApiUrl}${url}`
  }

  endpoint(url: string): string {
    return this.baseEndpoint
      ? `/${this.baseEndpoint}/${url}`
      : `/${url}`;
  }
}
