import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CommentPacket } from '@lib/model/net/CommentPacket';
import { UserProfilePacket } from '@lib/model/net/userProfilePacket';
import { CommentRelationalMapPacket } from '@lib/model/net/CommentRelationalMapPacket';
import { Logger } from '@lib/util/logger';
import { BaseComponent } from '@lib/view/base.component';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { CommentService } from '../comment.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserProfileService } from '@app/user-profile/user-profile.service';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent extends BaseComponent implements OnInit {

  protected log: Logger = new Logger("AddCommentComponent");

  formGroup: FormGroup;

  // MODES: CREATE && EDIT
  mode: string = "CREATE";

  @Input()
  relationalType: string = "MI_CASE";

  @Input()
  relatedId: string = "-1";

  @Input()
  formattedUsername: string = "";

  userProfile?: UserProfilePacket;

  commentTypes?: string[] = [];
  selectedCommentType = "NONE";

  saveCommentComplete: Subject<any> = new Subject();
  cancelComment: Subject<any> = new Subject();

  existingComment?: any;

  userIsDataEntry = false;
  userIsAdmin = false;

  constructor(
    override snack: FennecSnackbarService,
    protected commentService: CommentService,
    protected userProfileService: UserProfileService,
    protected keycloakService: KeycloakService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<AddCommentComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any

  ) {
    super();
    if(data) {
      this.mode = data.mode;
      this.existingComment = data.comment;
      this.formattedUsername = data.formattedUsername;
      this.relatedId = data.relatedId;
      this.relationalType = data.relationalType;
    }

    this.formGroup = this.createFormGroup(this.existingComment);
    const roles = keycloakService.getUserRoles();

    this.userIsDataEntry = roles.includes("DATA_ENTRY");
    this.userIsAdmin = roles.includes("ADMIN");
   }

  ngOnInit(): void {
    this.performXFRequest({
      requestDescription: "GET Comment Types",
      requestFn: this.commentService.getCommentTypes,
      fnParams: [],
      onSuccess: (data) => {
        this.commentTypes = data;
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    })
  }

  createFormGroup = (existingComment:any = null) => {
    return new FormGroup({
      commentText: new FormControl(existingComment ? existingComment?.commentText : "")
    });
  }

  clearFormGroup = () => {
    this.formGroup = this.createFormGroup();
  }

  cancelAdd = () => {
    this.clearFormGroup();
    this.dialogRef.close();
  }

  saveCommentToServer = () => {
    if(this.relatedId == "-1") {
      return;
    }

    const commentPacket = new CommentPacket();
    
    commentPacket.commentText = this.formGroup.controls["commentText"].value;
    if(this.userIsAdmin) {
      commentPacket.commentType = this.selectedCommentType;
    }else if(this.userIsDataEntry) {
      commentPacket.commentType = "DE_FEEDBACK"
    }else {
      commentPacket.commentType = "CLIENT_COMMENT"
    }

    const packet = new CommentRelationalMapPacket();
    packet.comment = commentPacket;
    packet.relatedId = parseInt(this.relatedId);
    packet.commentRelationalType = this.relationalType;

    
    if(this.relationalType == "MI_CASE") {
      packet.miCaseId = packet.relatedId;
    }

    // Changes function/params depending on mode
    let requestFunction = this.commentService.createComment
    let params = packet;

    if (this.mode === "EDIT") {
      requestFunction = this.commentService.editComment;
      params = commentPacket;
      commentPacket.id = this.existingComment.id;
    }

    this.performXFRequest({
      requestDescription: "Save Comment",
      requestFn: requestFunction,
      fnParams: [params],
      onSuccess: (data) => {
        this.clearFormGroup();
        this.dialogRef.close();
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });  
  }

  // Returns True if commentText is empty.  Used to prevent Validators.required always discoloring comment input on component load
  manualValidation = () => {
    const control = this.formGroup.controls['commentText'];
    if(control && control.value) {
      return control.value.length === 0;
    }

    return true;
  }

}
