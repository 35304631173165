import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AttachmentService } from '@app/mi-case/mi-case-attachment/attachment.service';
import { ItemizedUpdatePacket } from '@lib/model/net/itemized-update-packet';
import { ItemizedService } from '../itemized.service';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';

@Component({
  selector: 'app-itemized-create',
  templateUrl: './itemized-create.component.html',
  styleUrls: ['./itemized-create.component.css']
})
export class ItemizedCreateComponent implements OnInit {

  formGroup!: FormGroup;
  typeList: String[] = [];

  constructor(
    private attachmentService: AttachmentService,
    public dialogRef: MatDialogRef<ItemizedCreateComponent>,
    private snackBar: FennecSnackbarService,
    private itemizedService: ItemizedService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.formGroup = new FormGroup({
      externalAltId: new FormControl("") 
    });
    if (data.ub04Id === null || data.ub04Id === undefined) {
      data.ub04Id = -1;
    }
  }

  ngOnInit() {
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    let itemizedDto = new ItemizedUpdatePacket();
    itemizedDto.miCaseId = this.data.miCaseId;
    itemizedDto.ub04Id = this.data.ub04Id;
    itemizedDto.externalAltId = this.formGroup.controls['externalAltId'].value;    
    if (this.data.origin === "MANUAL") {
      this.itemizedService.addItemized(itemizedDto).subscribe(response => {
        if (response.hasErrors) {
          this.snackBar.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          const returnObj = {
            confirm: true,
            id: response.data.id
          }
          this.dialogRef.close(returnObj);
          this.snackBar.showSuccessSnack(`Successfully Created!`);
        }
      });
    } else {
      this.attachmentService.convertToItemized(this.data.attachmentId, itemizedDto).subscribe((response: any) => {
        if (response.hasErrors) {
          this.snackBar.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          const returnObj = {
            confirm: true,
            id: response.data.id
          }
          this.dialogRef.close(returnObj);
          this.snackBar.showWorkerSubmitSnack("Process/Create Itemized initiated!");
        }
      });
    }
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

}
