import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserProfileService } from '@app/user-profile/user-profile.service';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';
import { BaseResponse } from '@lib/model/base-response';
import { DateUtil } from '@lib/util/date-util';
import { MatLegacyOption as MatOption } from "@angular/material/legacy-core";
import { MatLegacySelect as MatSelect } from "@angular/material/legacy-select";
import { debounce, debounceTime, Subject, take, takeUntil } from "rxjs";
import { BaseComponent } from "@lib/view/base.component";
import { Logger } from "@lib/util/logger";
import { SimpleObject } from "@lib/model/simple-object";
import { AttachmentService } from '@app/mi-case/mi-case-attachment/attachment.service';
import { MICaseService } from 'xf-common';

@Component({
  selector: 'app-dashboard-filter-param',
  templateUrl: './dashboard-filter-param.component.html',
  styleUrls: ['./dashboard-filter-param.component.scss']
})
export class DashboardFilterParamComponent extends BaseComponent implements AfterViewInit, OnChanges {

  log = new Logger("DashboardFilterParamComponent");

  @Input()
  title: string = "Filter Parameters";

  @Input()
  paramConfig: any[] = [];

  @ViewChildren('attachmentTypeSelect') attachmentTypeSelect!: QueryList<MatSelect>;
  @ViewChildren('clientSelect') clientSelect!: QueryList<MatSelect>;
  @ViewChildren('hcpSelect') hcpSelect!: QueryList<MatSelect>;
  @ViewChildren('miCaseTypeSelect') miCaseTypeSelect!: QueryList<MatSelect>;
  @ViewChildren('lobSelect') lobSelect!: QueryList<MatSelect>;

  // The list of primary clients the user is authorized to see (visibility).
  primaryClients: any [] = [];

  // The list of health care plans the user is authorized to see (visibility). This list should stay static. We present a different
  // health care plan list to the user on the UI that is filtered by the primary clients they select. We build that one using this
  // static list as the basis.
  healthCarePlansStatic: any [] = [];

  // A dynamic list of health care plans that changes corresponding to the primary clients that the user selects.
  healthCarePlans: any [] = [];

  // Attachment Types - if parameter filter value is requested - otherwise empty.
  attachmentTypes: string [] = [];

  // MICase Types - if parameter filter value is requested - otherwise empty.
  miCaseTypes: any [] = [];

  // lineAdjUsers - if parameter filter value is requested - otherwise empty
  // Note: distinct MICaseUser (type: LINE_ADJ) recs on UserProfileId
  lineAdjUsers: any [] = [];

  // lob - Line of Business values - if parameter filter value is requested - otherwise empty.
  lineOfBusinessValues: any [] = [];

  attachmentTypeSelectAll: boolean = false;
  miCaseTypeSelectAll: boolean = false;
  hcpSelectAll: boolean = false;
  pcSelectAll: boolean = false;
  lobSelectAll: boolean = false;

  requestAttemptDelay = 1000; // this number will keep increasing while we're unable to fetch data from the server

  readonly defaultParamConfig: any [] = [
    {
      label: "Provider Name", dashboardFilterParamType: "PRIMARY_PROVIDER_NAME",
      dataType: "string", formControlName: "providerName"
    },
    {
      label: "From Date", dashboardFilterParamType: "FROM_DATE",
      dataType: "date", formControlName: "fromDate", defaultValue: "2000-01-01"
    },
    {
      label: "Thru Date", dashboardFilterParamType: "THRU_DATE",
      dataType: "date", formControlName: "thruDate", defaultValue: "2050-12-31"
    },
  ];

  // Form Group for configured filter fields
  formGroup!: FormGroup;

  // Form Group that just manages the 'select all' option on primary client fields. Right now we'll just have one control in
  // here for a single primary client control. If we get to a point where we may need multiple primary_client filters, we will
  // need to re-design this.
  pcSelectAllFormGroup: FormGroup;

  rebuildForm$ = new Subject<void>();
  rebuildHcpList$ = new Subject<void>();

  constructor(
    private attachmentService: AttachmentService,
    private miCaseService: MICaseService,
    protected snack: FennecSnackbarService,
    private userProfileService: UserProfileService
  ) {
    super();

    // To manage the 'select all' values for primary client.
    this.pcSelectAllFormGroup = new FormGroup({});
    this.pcSelectAllFormGroup.addControl("pcSelectAll", new FormControl(false));
    this.pcSelectAllFormGroup.addControl("hcpSelectAll", new FormControl(false));
    this.pcSelectAllFormGroup.addControl("attachmentTypeSelectAll", new FormControl(false));
    this.pcSelectAllFormGroup.addControl("miCaseTypeSelectAll", new FormControl(false));
    this.pcSelectAllFormGroup.addControl("lobSelectAll", new FormControl(false));

    this.rebuildForm$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(100)
    ).subscribe(() => {
      this.formGroup = this.createFormGroup();
    });
    this.rebuildHcpList$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(100)
    ).subscribe(() => {
      this.buildDynamicHealthCarePlanList();
    });
  }

  ngAfterViewInit() {
    this.getUserProfileMICaseVisibilityInfo();
    setTimeout(() => {
      if (this.formGroup == null) {
        this.rebuildForm$.next();
      }
    }, 100);
  }

  // Load up parameter values based upon whether they are needed or not.
  performConditionalParameterValueLoading() {
    let attachmentTypes: boolean = false;
    let lob: boolean = false;
    let miCaseTypes: boolean = false;
    let miCaseUsersLineItemAdj: boolean = false;
    this.paramConfig.forEach((pc) => {
      if (pc.dashboardFilterParamType === "ATTACHMENT_TYPES") {
        attachmentTypes = true;
      } else if (pc.dashboardFilterParamType === "MI_CASE_USERS_LINE_ITEM_ADJ") {
        miCaseUsersLineItemAdj = true;
      } else if (pc.dashboardFilterParamType === "MI_CASE_TYPES") {
        miCaseTypes = true;
      } else if (pc.dashboardFilterParamType === "LOB") {
        lob = true;
      }
    });
    if (attachmentTypes) {
      this.attachmentService.getAttachmentTypes().subscribe((response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.attachmentTypes = response.data;
        }
      });
    }
    if (miCaseUsersLineItemAdj) {
      this.userProfileService.getDistinctUserProfileForMICaseUserType("LINE_ITEM_ADJ").subscribe((response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.lineAdjUsers = response.data;
        }
      });
    }
    if (miCaseTypes) {
      this.performXFRequest({
        requestDescription: "Get Case Types",
        requestFn: this.miCaseService.getCaseTypes,
        fnParams: [],
        onSuccess: (data) => {
          this.miCaseTypes = data;
        }
      });
    }
    if (lob) {
      this.performXFRequest({
        requestDescription: "Get Line of Business Values",
        requestFn: this.miCaseService.getLinesOfBusiness,
        fnParams: [],
        onSuccess: (data) => {
          this.lineOfBusinessValues = data;
        }
      });
    }
  }

  getUserProfileMICaseVisibilityInfo() {
    this.performXFRequest({
      requestDescription: 'Get case visibility info',
      requestFn: this.userProfileService.getUserProfileMICaseVisibilityInfo,
      fnParams: [],
      onSuccess: data => {
        const userVisibilityLevel = data.visibilityLevel;
        this.primaryClients = data.primaryClients;
        if (userVisibilityLevel === "HEALTH_CARE_PLAN") {
          this.healthCarePlansStatic = data.userHealthCarePlans;
        } else if (userVisibilityLevel === "PRIMARY_CLIENT") {
          this.healthCarePlansStatic = data.allHealthCarePlans;
        }
        this.rebuildHcpList$.next();
      },
      onError: err => {
        this.snack.showErrorSnack(`${err};\ntrying again in ${this.requestAttemptDelay/1000} seconds`);
        setTimeout(() => {
          this.getUserProfileMICaseVisibilityInfo();
        }, this.requestAttemptDelay);
        this.requestAttemptDelay *= 2;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes["paramConfig"]) {
      if (this.paramConfig.length > 0) {
        this.performConditionalParameterValueLoading();
      }
      this.rebuildForm$.next();
    }
  }

  createFormGroup(): FormGroup {
    let controls: SimpleObject = {};
    this.paramConfig.forEach((pc: any) => {
      let fc = new FormControl();
      if (pc.dataType != null && pc.dataType.toLowerCase() === "date") {
        fc.addValidators([Validators.pattern(DateUtil.STD_DATE_VALIDATOR_PATTERN)]);
        // Check if value is supplied. Usually this is when the user accesses the filters more
        // than once - the value is the previous value they entered and we're re-prompting them
        // here with that value. If the value is not there, use the default value (this is usually
        // the first time they are putting in filter values)
        if (pc.value != null) {
          let dateValue: string = pc.value;
          // Convert YYYY-MM-DD values to MM/DD/YYYY
          if (dateValue.length === 10) {
            dateValue = DateUtil.getDisplayDateYYYY(dateValue);
          }
          fc.setValue(dateValue);
        } else if (pc.defaultValue !== null && pc.defaultValue !== undefined) {
          let defaultDateValue: string = pc.defaultValue;
          // Convert YYYY-MM-DD values to MM/DD/YYYY
          if (defaultDateValue.length === 10) {
            defaultDateValue = DateUtil.getDisplayDateYYYY(defaultDateValue);
          }
          fc.setValue(defaultDateValue);
        }
      } else {
        if (pc.value != null) {
          // Set the value of the pcSelectAll checkbox based if the number of ids passed in equals the number of
          // recs in the primary client array.
          if (pc.dataType != null && pc.dataType.toLowerCase() === "primary_client") {
            this.pcSelectAllFormGroup.controls['pcSelectAll'].setValue(pc.value.length === this.primaryClients.length);
            fc.setValue(pc.value);
          } else if (pc.dataType != null && pc.dataType.toLowerCase() === "health_care_plan") {
            // Note: seting the value of the 'select all' checkbox for health care plans is done in the dynamic building of
            // the health care plan list method.
            // this.pcSelectAllFormGroup.controls['hcpSelectAll'].setValue(pc.value.length === this.healthCarePlans.length);
            fc.setValue(pc.value);
          } else if (pc.dataType != null && pc.dataType.toLowerCase() === "attachment_type") {
            this.pcSelectAllFormGroup.controls['attachmentTypeSelectAll'].setValue(pc.value.split(",").length === this.attachmentTypes.length);
            fc.setValue(pc.value.split(","));
          } else if (pc.dataType != null && pc.dataType.toLowerCase() === "mi_case_type") {
            this.pcSelectAllFormGroup.controls['miCaseTypeSelectAll'].setValue(pc.value.split(",").length === this.miCaseTypes.length);
            fc.setValue(pc.value.split(","));
          } else if (pc.dataType != null && pc.dataType.toLowerCase() === "lob") {
            this.pcSelectAllFormGroup.controls['lobSelectAll'].setValue(pc.value.split(",").length === this.lineOfBusinessValues.length);
            fc.setValue(pc.value.split(","));
          } else {
            fc.setValue(pc.value);
          }
        } else if (pc.defaultValue != null) {
          if (pc.dataType != null && pc.dataType.toLowerCase() === "primary_client") {
            // No default values here for primary client yet. We may want to build this out later but we'll
            // have to ping the server for information on primary clients in order to do this.
            fc.setValue([]);
          } else {
            // Default values are assumed to be string types here.
            fc.setValue(pc.defaultValue);
          }
        }
      }
      if (pc.required) {
        fc.addValidators(Validators.required);
      }
      controls[pc.formControlName] = fc;
    });
    const fg = new FormGroup(controls);
    fg?.controls?.['primaryClient']?.valueChanges.pipe(
      takeUntil(this.destroyed$),
      debounceTime(100)
    ).subscribe(() => this.rebuildHcpList$.next());

    fg?.controls?.['healthCarePlan']?.valueChanges.subscribe(() => {

    });
    return fg;
  }

  onAttachmentTypeSelectAll(event: any) {
    let select = !!event.checked;
    this.attachmentTypeSelect?.first?.options?.forEach((item: MatOption) => select ? item.select() : item.deselect());
  }

  onMICaseTypeSelectAll(event: any) {
    let select = !!event.checked;
    this.miCaseTypeSelect?.first?.options?.forEach((item: MatOption) => select ? item.select() : item.deselect());
  }

  onLOBSelectAll(event: any) {
    let select = !!event.checked;
    this.lobSelect?.first?.options?.forEach((item: MatOption) => select ? item.select() : item.deselect());
  }

  onPCSelectAll(event: any) {
    let select = !!event.checked;
    this.clientSelect?.first?.options?.forEach((item: MatOption) => select ? item.select() : item.deselect());
  }

  onHCPSelectAll(event: any) {
    let select = !!event.checked;
    this.hcpSelect?.first?.options?.forEach((item: MatOption) => select ? item.select() : item.deselect());
  }

  onPCChange(event: any) {
    this.rebuildHcpList$.next();
  }

  buildDynamicHealthCarePlanList() {
    let healthCarePlans: any[] = [];
    if (!!this.pcSelectAllFormGroup.controls['hcpSelectAll']) {
      this.pcSelectAllFormGroup.controls['hcpSelectAll'].setValue(false);
    }
    if (!!this.formGroup?.controls['healthCarePlan'] && !!this.formGroup?.controls['primaryClient']) {
      let selectedPrimaryClients: any [] = this.formGroup.controls['primaryClient'].value;
      this.healthCarePlansStatic.forEach((hcp) => {
        if (selectedPrimaryClients.includes(hcp.companyId)) {
          healthCarePlans.push(hcp);
        }
      });
    }
    setTimeout(() => {
      this.healthCarePlans = healthCarePlans;
      // Set's the 'select all' health care checkbox based upon the value that it's in the formgroup at this refresh.
      if (this.formGroup?.controls['healthCarePlan']?.value !== undefined && this.formGroup?.controls['healthCarePlan']?.value?.length > 0) {
        this.pcSelectAllFormGroup?.controls['hcpSelectAll'].setValue(this.formGroup?.controls['healthCarePlan'].value.length === this.healthCarePlans.length);
      }
      // this.formGroup.controls['healthCarePlan'].setValue(healthCarePlans);
    });

  }

  // F2 on any date field
  // Used for local development - will auto fill fromDate and thruDate with values that coincide with expected test data.
  testDataDate() {
    this.formGroup?.controls?.['fromDate']?.setValue("01/01/2022");
    this.formGroup?.controls?.['thruDate']?.setValue("01/31/2022");
  }

}
