import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentListComponent } from './comment-list/comment-list.component';
import { CommentService } from './comment.service';
import { CommentListDialogComponent } from './comment-list-dialog/comment-list-dialog.component';
import { MaterialModule } from '@lib/view/material.module';
import { AddCommentComponent } from './add-comment/add-comment.component';



@NgModule({
  declarations: [
    CommentListComponent,
    AddCommentComponent,
    CommentListDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    CommentListComponent,
    AddCommentComponent,
    CommentListDialogComponent
  ],
  providers: [
    CommentService,
    
  ]
})
export class CommentsModule { }
