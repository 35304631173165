import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ItemizedRevisionUpdatePacket } from '@lib/model/net/itemized-revision-update-packet';
import { ItemizedUpdatePacket } from '@lib/model/net/itemized-update-packet';
import { KeycloakService } from 'keycloak-angular';
import { ItemizedRevisionService } from '../itemized-revision.service';
import { FennecSnackbarService } from '@lib/dialog/fennec-snackbar/fennec-snackbar.service';

@Component({
  selector: 'app-itemized-revision-create',
  templateUrl: './itemized-revision-create.component.html',
  styleUrls: ['./itemized-revision-create.component.css']
})
export class ItemizedRevisionCreateComponent implements OnInit {

  formGroup!: FormGroup;
  typeList: String[] = [];

  constructor(
    public dialogRef: MatDialogRef<ItemizedRevisionCreateComponent>,
    private snack: FennecSnackbarService,
    private itemizedRevisionService: ItemizedRevisionService,
    private keycloakService: KeycloakService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    let name: string = "";
    if (keycloakService.isUserInRole("DATA_ENTRY")) {
      name = "Original Data Entry";
    }
    this.formGroup = new FormGroup({
      name: new FormControl(name, Validators.required) 
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    let iDto = new ItemizedRevisionUpdatePacket();
    iDto.id = -1;
    iDto.itemizedId = this.data.itemizedId;
    iDto.name = this.formGroup.controls['name'].value;

    this.itemizedRevisionService.addItemizedRevision(iDto).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true,
          id: response.data.id
        }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack(`Successfully Created!`);
      }
    })
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

}
