<div class="page" tabindex="0" [autoFocus]="true">
  <div class="page-wrapper">
    <div class="page-title">
      <ng-content select="[pageTitle]"></ng-content>
    </div>
    <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
  </div>
</div>

<ng-template #fullViewTemplate>
  <div class="page-toolbar">
    <ng-content select="[pageToolbar]"></ng-content>
  </div>
  <div class="page-content">
    <ng-content select="[pageContent]"></ng-content>
  </div>
  <div class="page-actions">
    <ng-content select="[pageActions]"></ng-content>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div
    *ngIf="canShowLoadingIndicator && isLoading; else noDataFoundTemplate"
    class="loading-container"
  >
    <div class="loading-graphic">
      <mat-spinner [diameter]="18" color="accent"></mat-spinner>
      <span class="loading-text">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #noDataFoundTemplate>
  <div
    *ngIf="canShowNoDataFound && noDataFound; else fullViewTemplate"
    class="no-data-found-container"
  >
    <div class="custom-no-data-message no-data-found-text"><ng-content select="[pageNoData]"></ng-content></div>
    <div class="no-data-found-text">No data found</div>
  </div>
</ng-template>
