import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'lib-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent {
  @Input()
  public name: string = "";

  @Input()
  public isLoading = false;

  @Input()
  public canShowLoadingIndicator = true;

  @Input()
  public noDataFound = false;

  @Input()
  public canShowNoDataFound = true;

  @Output()
  public error = new EventEmitter<string>();

  public constructor() {}
}
