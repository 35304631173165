import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PagedResponse } from "@lib/model/paged-response";
import { Observable } from "rxjs";
import { EnvironmentService } from "xf-common";

@Injectable({
    providedIn: 'root'
  })
export class ActivityDiaryService {

    constructor(
        private httpClient: HttpClient,
        private environment: EnvironmentService
    ) {}
        getRecentActivityList(first?: number, max?: number): Observable<PagedResponse<any>> {
            return this.httpClient.get<any>(`${this.environment.baseApiUrl}activity-diary/list/user/?first=${first}&max=${max}`);
        }

}
